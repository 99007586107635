import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SurveyIcon from '@mui/icons-material/AirplanemodeActive';
import UploadIcon from '@mui/icons-material/CloudUpload';

import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { SideBarMenuItem } from '../../redux/app/appReducer';
import { List } from '@mui/material';

const icons : Record<string, () => React.ReactElement> = {
  'survey': () => <SurveyIcon />,
  'upload': () => <UploadIcon />
}
export const AutoSelectListItemButton = (props: any) => {
  const location = useLocation()
  const { to, label, icon, indent } = props
  const selected = location.pathname === to 
  return (<ListItemButton selected={selected} component={NavLink}  to={to}>
      <ListItemIcon sx={{ marginLeft: indent }}>
        {icons[icon]()}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>)
}

export const ListItems = (props : any) => {
  const menuItems : SideBarMenuItem[] = props.menuItems
  const indent = props.indent || 0
  return (
    <List >
      {menuItems.map((item, index) => (
        <AutoSelectListItemButton indent={indent} key={index} to={item.path} label={item.title} icon={item.icon} />
      ))}
    </List>
  )
}