import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {
  CognitoChallengeParam,
  APP_SET_API_VERSION,
  APP_SET_CHALLENGE,
  APP_RESET_CHALLENGE,
  APP_SET_LOGGED_OUT,
  APP_SET_USER,
  SetApiVersionAction,
  SetChallengeAction,
  SetUserAction,
  SignInCognitoResponse,
  APP_SET_SECONDARY_MENU,
  SetSecondaryMenuAction,
} from './appActions'

// Declare the global variable
declare const FLYDATA_AUDIO_WEB_VERSION: string

export interface User {
  username?: string
  token?: string
  groups?: string[]
}

export type ChallengeName = 'NEW_PASSWORD_REQUIRED' | 'FORGOT_PASSWORD' | 'PASSWORD_RESET_REQUIRED'

export interface UserState {
  user: User | null;
  session?: CognitoUserSession;
  challenge?: ChallengeName;
}

export interface AuthChallenge {
  user: SignInCognitoResponse
  name: string
  param: CognitoChallengeParam
}

export interface SideBarMenuItem {
  title: string
  icon: string
  path: string
}

export interface SideBarMenu {
  items: SideBarMenuItem[]
}

export interface AppStore {
  uiVersion: string
  apiVersion: string
  challenge: AuthChallenge | null
  loggedIn: boolean
  user: User | null
  authReady: boolean
  menu: SideBarMenu
  secondaryMenu: SideBarMenu
}

const initialState: AppStore = {
  uiVersion: FLYDATA_AUDIO_WEB_VERSION,
  apiVersion: '',
  challenge: null,
  loggedIn: false,
  user: null,
  authReady: false,
  menu: {
    items: [
      { title: 'Missions', icon: 'survey', path: '/missions'}
    ]
  },
  secondaryMenu: {
    items: []
  }
}

const app = (state = initialState, action: any) => {
  switch (action.type) {
    case APP_SET_API_VERSION:
      const setApiVersion: SetApiVersionAction = action
      return { ...state, apiVersion: setApiVersion.payload.version }
    case APP_SET_CHALLENGE:
      const setChallengeAction: SetChallengeAction = action
      return {
        ...state,
        user: null,
        challenge: {
          user: setChallengeAction.payload.user,
          name: setChallengeAction.payload.challengeName,
          param: setChallengeAction.payload.challengeParam,
        },
        loggedIn: false,
      }
    case APP_RESET_CHALLENGE:
      return { ...state, challenge: null }
    case APP_SET_LOGGED_OUT:
      return { ...state, user: null, authReady: true, loggedIn: false, challenge: null }
    case APP_SET_USER:
      const setUserAction: SetUserAction = action
      return { ...state, user: setUserAction.payload, authReady: true, loggedIn: true, challenge: null }
    case APP_SET_SECONDARY_MENU:
      const setSecondaryMenuAction = action as SetSecondaryMenuAction
      return { ...state, secondaryMenu: { items: setSecondaryMenuAction.payload.items } }
    default:
      return state
  }
}

export default app
