import { useState } from 'react'
import { AudioFileMetadata, Observation } from '../../redux/missionsModel'
import { formatSeconds } from '../../services/timeUtil'
import StaticDataList, { ConditionEquals, ConditionalCell, DeleteRowButton, TextFieldCell, UnixTimeOnlyCell } from '../datalist/StaticDataList'
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { deleteObservation, updateObservation } from '../../redux/audioFileEdit/audioFileEditActions'
import CheckIcon  from '@mui/icons-material/Check';
import EditObservationDialog from './EditObservationDialog'
export interface ListObservationsProps {
  missionId: string
  audioFileId: string
  metadata: AudioFileMetadata
  observations: Observation[]
}

const ListObservations = ({ missionId, audioFileId, metadata, observations }: ListObservationsProps) => {
  const [selectedObservation, setSelectedObservation] = useState<Observation | undefined>(undefined)

  const dispatch = useDispatch()

  return (
    <>
      <EditObservationDialog 
        selectedObservation={selectedObservation} 
        onClose={() => setSelectedObservation(undefined)}
        onSave={(observation : Observation) => {dispatch(updateObservation(missionId, audioFileId, observation)); setSelectedObservation(undefined)}}
         />
      <StaticDataList
        onClick={(item) => {
          setSelectedObservation(item)
        }}
        dense
        items={observations}
      >
        <TextFieldCell source="species" title="Species" />
        <TextFieldCell source="count" title="Count" />

        <TextFieldCell source="intensity" title="Intensity" />
        <TextFieldCell source="distanceBand" title="Distance band" />
        <TextFieldCell source="age" title="Age" />
        <TextFieldCell source="behaviour" title="Behaviour" />
        <TextFieldCell sourceFn={(item: any) => formatSeconds(item.audioTime)} title="Audio time" />
        <UnixTimeOnlyCell
          title="Timestamp"
          utc
          unit="ms"
          sourceFn={(item: any) => `${item.audioTime * 1000 + metadata.startTime}`}
        />
        <TextFieldCell source="observationType" title="Type" />
        <ConditionalCell source="status" title="Status">
          <ConditionEquals case="PENDING">
          <CircularProgress sx={{ marginLeft: 2 }} color='primary' size={12} />
          </ConditionEquals>
          <ConditionEquals case="SAVED">
            <div style={{ maxHeight: 14 }}>
              <CheckIcon sx={{ marginTop: -1 }} color='success' fontSize='small' />
            </div>
          </ConditionEquals>
        </ConditionalCell>
        <TextFieldCell source="comment" title="Comment" />
        <DeleteRowButton disabledFn={item => item.status === 'PENDING'} title='' onClick={(item : Observation) => dispatch(deleteObservation(missionId, audioFileId, item.id))} />
      </StaticDataList>
    </>
  )
}

export default ListObservations
