import { useRef } from 'react'
import { 
    Button
} from '@mui/material'

export type UploadButtonColors = "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning" 

export interface UploadButtonProps {
    label: string;
    disabled?: boolean;
    fullWidth?: boolean;
    color?: UploadButtonColors;
    variant?: 'contained' | 'outlined' | 'text';
    multiple?  : boolean;
    onChange: (evt : any, value : (FileList | null)) => void
}

const UploadButton = ({ multiple, color, fullWidth, variant, label, disabled, onChange }: UploadButtonProps) => {
    const _variant = variant || 'contained'
    const inputRef = useRef<any>(null)
    return  <Button
      color={color}
      fullWidth={fullWidth}
      disabled={disabled}
      variant={_variant}
      component="label"
  >
    {label}
    <input
      ref={inputRef}
      type="file"
      hidden
      multiple={multiple}
      onClick={() => {
        // Reset value onClick
        if (inputRef && inputRef.current) {
          inputRef.current.value = null                  
        }
      }}
      onChange={(evt) => {
        onChange(evt, evt.target.files)        
      }}
    />
  </Button>
}

export default UploadButton