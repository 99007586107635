import { delay, takeLeading, takeEvery, put, apply, select, takeLatest } from '@redux-saga/core/effects'

import { showMessage } from '../ui/uiActions'
import flydataAudioApi, {
  ApiResponse,
  FetchObservationsResponse,
  FetchResolutionResponse,
  UpdateObservationResponse,
} from '../../services/flydataAudioApi'
import { AudioFile, AudioFileMetadata, AudioFileSummary, AudioFileWithUrl, Mission, Observation } from '../missionsModel'
import {
  AUDIO_FILE_DO_DELETE_OBSERVATION,
  AUDIO_FILE_DO_UPDATE_OBSERVATION,
  AUDIO_FILE_EDIT_INIT,
  AUDIO_FILE_FETCH_OBSERVATIONS,
  AUDIO_FILE_FETCH_RESOLUTION_SUMMARY,
  AUDIO_FILE_REQUEST_ADD_OBSERVATION,
  AUDIO_FILE_REQUEST_DELETE_OBSERVATION,
  AUDIO_FILE_REQUEST_UPDATE_OBSERVATION,
  AUDIO_FILE_SET_MISSION_ID,
  AUDIO_FILE_STORE_METADATA,
  AUDIO_FILE_UPDATE_SHADOW_METADATA,
  AudioFileDoDeleteObservationAction,
  AudioFileDoUpdateObservationAction,
  AudioFileEditInitAction,
  AudioFileFetchResolutionSummaryAction,
  AudioFileRequestAddObservationAction,
  AudioFileRequestDeleteObservationAction,
  AudioFileRequestUpdateObservationAction,
  AudioFileStoreMetadataAction,
  AudioFileUpdateShadowMetadataAction,
  audioFileEditInit,
  audioFileFetchObservations,
  audioFileFetchResolutionSummary,
  audioFileSetUrl,
  clearAudioFileResolutionSummary,
  doDeleteObservation,
  doUpdateObservation,
  setAudioFile,
  setAudioFileObservations,
  setAudioFileResolutionSummary,
  setAudioFileShadowMetadata,
} from './audioFileEditActions'
import { initMissionEdit } from '../missionEdit/missionEditActions'
import { AppState } from '../reducers'
import { produce } from 'immer'

function* processInit(action: AudioFileEditInitAction) {
  try {
    const { missionId, audioId } = action.payload

    yield put(clearAudioFileResolutionSummary())
    const mission: Mission = yield select((state: AppState) => state.missionEdit.mission)
    console.log('Mission', mission, missionId)
    yield put({ type: AUDIO_FILE_SET_MISSION_ID, payload: { missionId } })
    if (!mission || mission.id !== missionId) {
      yield put(initMissionEdit(missionId))
      console.log('Waiting for mission to load')
      yield delay(250)
      console.log('Retry')
      yield put(audioFileEditInit(missionId, audioId))
      console.log('put init')
      return
    }

    const audioFile = mission.audioFiles.find((e) => e.id === audioId)
    if (!audioFile) {
      yield put(showMessage({ title: 'Error', text: 'Audio file not found: ' + audioId }))
      return
    }

    yield put(setAudioFile(audioFile))
    yield put(audioFileFetchObservations(missionId, audioId))
    yield put(setAudioFileShadowMetadata(audioFile.metadata))

    const response: ApiResponse<AudioFileSummary> = yield apply(
      flydataAudioApi,
      flydataAudioApi.fetchAudioMetadataSummary,
      [missionId, audioId]
    )
    const audioResponse: ApiResponse<AudioFileWithUrl> = yield apply(flydataAudioApi, flydataAudioApi.getAudioUrl, [
      missionId,
      audioId,
    ])
    const audio = audioResponse.item

    for (let i = 0; i < response.item.resolutions.length; i++) {
      yield put(audioFileFetchResolutionSummary(missionId, audioId, response.item.resolutions[i]))
    }
    console.log({ resolutions: response.item.resolutions, url: audio.presignedUrl })
    yield put(audioFileSetUrl(audio.presignedUrl))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error fetching summary', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processFetchObservations(action: AudioFileEditInitAction) {
  try {
    const { missionId, audioId } = action.payload

    const response: FetchObservationsResponse = yield apply(flydataAudioApi, flydataAudioApi.fetchObservations, [
      missionId,
      audioId,
    ])
    if (response.lastEvaluatedKey) {
      console.log('More observations available')
    }
    yield put(setAudioFileObservations(response.items))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error fetching observations', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processFetchResolutionSummary(action: AudioFileFetchResolutionSummaryAction) {
  try {
    const { missionId, audioId, resolution } = action.payload
    let buckets: number[] = []
    for (let i = 0; i < resolution.fileCount; i++) {
      const mounted : boolean = yield select((state: AppState) => state.audioFileEdit.mounted)
      if (!mounted) {
        console.log('Not mounted: aborting resolution fetch')
        return
      }
      const audioSummary: FetchResolutionResponse = yield apply(
        flydataAudioApi,
        flydataAudioApi.fetchAudioSummaryResolution,
        [missionId, audioId, resolution.resolution, i]
      )
      buckets = buckets.concat(audioSummary.buckets)
    }
    console.log({ resolution: resolution.resolution, buckets })
    yield put(setAudioFileResolutionSummary(resolution.resolution, buckets))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error fetching resolitions', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processUpdateShadowMeta(action: AudioFileUpdateShadowMetadataAction) {
  try {
    const { prop, value } = action.payload
    let shadow: AudioFileMetadata | undefined = yield select((state: AppState) => state.audioFileEdit.metadataShadow)

    let newShadow: AudioFileMetadata | undefined = produce(shadow, (draft: AudioFileMetadata | undefined) => {
      if (draft) {
        //@ts-expect-error
        draft[prop] = value
      } else {
        draft = { [prop]: value } as AudioFileMetadata
      }
    })
    console.log({ newShadow })
    yield put(setAudioFileShadowMetadata(newShadow))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error updating audiofile metadata', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processStoreMetadata(action: AudioFileStoreMetadataAction) {
  try {
    const { metadata } = action.payload
    const missionId: string = yield select((state: AppState) => state.missionEdit.mission?.id)
    const audioFile: AudioFile | undefined = yield select((state: AppState) => state.audioFileEdit.audioFile)
    const audioId: string | undefined = audioFile?.id
    if (!audioFile || !missionId || !audioId) {
      yield put(showMessage({ title: 'Error', text: 'Mission or audio file not found' }))
      return
    }

    console.log('storeMetadata', { missionId, audioId, metadata })
    yield apply(flydataAudioApi, flydataAudioApi.updateAudioMetadata, [missionId, audioId, metadata])

    const newAudioFile = produce(audioFile, (draft: AudioFile) => {
      draft.metadata = metadata
    })
    yield put(setAudioFile(newAudioFile))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error storing metadata', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processRequestAddObservation(action: AudioFileRequestAddObservationAction) {
  try {
    const { missionId, audioFileId, observation } = action.payload
    console.log({ action })
    console.log({ obs: action.payload.observation })
    const response : { refId: string, observationId: string} = yield apply(flydataAudioApi, flydataAudioApi.storeObservation, [missionId, audioFileId, observation])

    const observations : Observation[] = yield select((state: AppState) => state.audioFileEdit.observations)
    const newObservations = observations.concat([{ ...observation, id: response.observationId, refId: response.refId}])
    yield put(setAudioFileObservations(newObservations))

    console.log({response})
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error adding observation', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processRequestUpdateObservation(action: AudioFileRequestUpdateObservationAction) {
  try {
    const { missionId, audioFileId, observation } = action.payload   
    yield put(doUpdateObservation(missionId, audioFileId, observation))
    const observations : Observation[] = yield select((state: AppState) => state.audioFileEdit.observations)
    const newObservations = observations.map((obs) => {
      if (obs.id === observation.id) {
        return { ...observation, status: 'PENDING' }
      }
      return obs
    })
    yield put(setAudioFileObservations(newObservations))
  } catch (error : any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error updating observation', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }  
  }
}


function* processDoUpdateObservation(action: AudioFileDoUpdateObservationAction) {
  try {
    const { missionId, audioFileId, observation } = action.payload   
    
    const response : UpdateObservationResponse = yield apply(flydataAudioApi, flydataAudioApi.updateObservation, [missionId, audioFileId, observation.id, observation])

    if (!response.ok) {
      yield put(showMessage({ title: 'Error', text: 'Unable to update observation' }))
      return
    }

    const observations : Observation[] = yield select((state: AppState) => state.audioFileEdit.observations)
    const newObservations = observations.map((obs) => {
      if (obs.id === observation.id) {
        return { ...response.observation, status: 'SAVED' }
      }
      return obs
    })
    yield put(setAudioFileObservations(newObservations))
  } catch (error : any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'API', text: 'Error updating observation: ' + error?.message }))
    } catch (e2) {
      console.log(e2)
    }  
  }
}

function* processRequestDeleteObservation(action: AudioFileRequestDeleteObservationAction) {
  try {
    const { missionId, audioFileId, observationId } = action.payload
    
    const observations : Observation[] = yield select((state: AppState) => state.audioFileEdit.observations)
    const newObservations = observations.map(e => {
      if (e.id === observationId) {
        return { ...e, status: 'PENDING' }
      }
      return e    
    })

    yield put(setAudioFileObservations(newObservations))
    yield put(doDeleteObservation(missionId, audioFileId, observationId))

  } catch (error : any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error deleting observation', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }  
  }
}


function* processDoDeleteObservation(action: AudioFileDoDeleteObservationAction) {
  try {
    const { missionId, audioFileId, observationId } = action.payload
        
    yield apply(flydataAudioApi, flydataAudioApi.deleteObservation, [missionId, audioFileId, observationId])
    yield put(audioFileFetchObservations(missionId, audioFileId))

  } catch (error : any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error deleting observation', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }  
  }
}

export default function* watcher() {
  yield takeEvery(AUDIO_FILE_EDIT_INIT, processInit)  
  yield takeEvery(AUDIO_FILE_FETCH_RESOLUTION_SUMMARY, processFetchResolutionSummary)
  yield takeLeading(AUDIO_FILE_UPDATE_SHADOW_METADATA, processUpdateShadowMeta)
  yield takeLatest(AUDIO_FILE_STORE_METADATA, processStoreMetadata)
  yield takeLeading(AUDIO_FILE_FETCH_OBSERVATIONS, processFetchObservations)
  yield takeEvery(AUDIO_FILE_REQUEST_ADD_OBSERVATION, processRequestAddObservation)
  yield takeEvery(AUDIO_FILE_REQUEST_UPDATE_OBSERVATION, processRequestUpdateObservation)
  yield takeEvery(AUDIO_FILE_DO_UPDATE_OBSERVATION, processDoUpdateObservation)
  yield takeEvery(AUDIO_FILE_REQUEST_DELETE_OBSERVATION, processRequestDeleteObservation)
  yield takeEvery(AUDIO_FILE_DO_DELETE_OBSERVATION, processDoDeleteObservation)
}
