import { Component } from "react";
import Animation from "../animation/Animation"

const resolutionsFromSummary = summary => {
  if (!summary) {
    return [];
  }

  // foreach property in summary, create a resolution object
  let resolutions = [];
  for (let key in summary) {
    if (summary.hasOwnProperty(key)) {
      let value = summary[key];
      resolutions.push({
        resolution: key,
        buckets: value
      });
    }
  }
  return resolutions
}

class Waveform extends Component {

  drawWaveform = (ctx, currentTime, duration, width, height, peakHeight, center, audioWidth, resolutions, smoothing, hightlightArea) => {
    if (!resolutions || resolutions.length === 0) {
      //console.log('no resolutions')
      return
    }
    let samplesPerSecond = 44100
    if (Number.isNaN(duration)) {
      //console.log("nan")
    }
    let offsetSeconds = this.calcOffsetSeconds(currentTime, duration, audioWidth)
    let secondsPerPixel = audioWidth / width
    let samplesPerPixel = secondsPerPixel * samplesPerSecond
    let summary = []
    let summaryResolution = 0

    resolutions.forEach((e, idx) => {
      if (e.resolution < samplesPerPixel && e.resolution > summaryResolution) {
        summaryResolution = e.resolution
        summary = e.buckets
      } else if (Number.isNaN(samplesPerPixel) && e.resolution > summaryResolution) {
        summaryResolution = e.resolution
        summary = e.buckets
      }
    })

    // console.log("spp, sr", samplesPerPixel, summaryResolution, summary.length)

    let bucketsPerSecond = samplesPerSecond / summaryResolution
    let offsetBuckets = offsetSeconds * bucketsPerSecond
    let bucketsPerPixel = samplesPerPixel / summaryResolution

    let currentSample = currentTime * samplesPerSecond
    let currentBucket = Math.floor(currentSample) / summaryResolution

    if (bucketsPerPixel >= 1) {
      offsetBuckets = Math.floor(offsetBuckets / Math.floor(bucketsPerPixel)) * Math.floor(bucketsPerPixel)
    } else if (summaryResolution === 65536) {
      offsetBuckets = 0
      offsetSeconds = 0
    }

    if (hightlightArea) {
      let x = hightlightArea.start / secondsPerPixel
      let w = hightlightArea.length / secondsPerPixel
      ctx.fillStyle = "#f8f8f8";
      ctx.fillRect(x, center - peakHeight, w, peakHeight * 2);
    }
    ctx.fillStyle = "#FFCC31";



    //console.log(spp, summary.length, width)
    for (var x = 0; x < width; x += 1) {
      let idx = x * bucketsPerPixel + offsetBuckets;
      idx = Math.floor(idx);
      if (bucketsPerPixel >= 1) {
        idx = Math.floor(idx / Math.floor(bucketsPerPixel)) * Math.floor(bucketsPerPixel) - (smoothing) * Math.floor(bucketsPerPixel)
      }
      if (idx < 0) idx = 0
      if (idx >= summary.length) {
        idx = summary.length - 1;
      }
      let max = summary[idx];
      let sum = summary[idx]
      let n = 1
      let q = 1 + (2 * smoothing)
      for (var j = idx + 1; j < (idx + q * bucketsPerPixel) && j < summary.length; j++) {
        let k = summary[j];
        if (k > max) {
          max = summary[j];
        }
        if (j < (idx + smoothing * bucketsPerPixel) || j > (idx + (smoothing + 1) * bucketsPerPixel)) {
          k = 0.5 * k
          n -= 0.5
        }
        sum += k
        n += 1
      }
      let amp = max * peakHeight;
      amp = (sum / n) * peakHeight
      if (idx >= currentBucket) {
        //ctx.fillStyle = "#E88B01";
        ctx.fillStyle = "#6A9"
      }
      ctx.fillRect(x, center - amp, 1, amp * 2);
    }

  }

  draw = (ctx, data, width, height, fps) => {
    if (!data) {
      return;
    }

    const { currentTime, summary, duration } = data;
    if (!summary) {
      return
    }
    const resolutions = resolutionsFromSummary(summary);
    // console.log({resolutions})
    //console.log(percentage, width, w);
    //console.log(percentage)
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = "#ddd";
    ctx.fillRect(0, 0, width, height);

    //ctx.fillStyle = "#000";
    //ctx.font = "20px Arial";
    //ctx.fillText(currentTime, 10, 24);

    const peakHeight = (height / 5)
    const center = height / 4 + height / 2;
    const audioWidth = this.props.audioWidth; // seconds
    this.drawWaveform(ctx, currentTime, duration, width, height, peakHeight, center, audioWidth, resolutions, 2)
    const peak65 = height / 8
    const center65 = height / 4;
    //console.log("s65", s65536)
    this.drawWaveform(ctx, currentTime, duration, width, height, peak65, center65, duration, resolutions, 1, { start: this.calcOffsetSeconds(currentTime, duration, audioWidth), length: audioWidth })

    ctx.restore();
  };

  onCanvasClick = evt => {
    const {currentTime, duration} = this.props.getAudioData()
    console.log({x: evt.x, y: evt.y})
    if (evt.y > this.props.height / 2) {
      let audioWidth = this.props.audioWidth
      let offsetSeconds = this.calcOffsetSeconds(currentTime, duration, this.props.audioWidth)
      let secondsPerPixel = audioWidth / this.props.width
      let secondsClicked = evt.x * secondsPerPixel
      this.props.onSeek(secondsClicked + offsetSeconds)
    } else {
      const { x, y } = evt
      const pct = x / this.props.width      
      const seek = ~~(duration * pct)
      console.log({ x, y, pct, duration, seek });
      this.props.onSeek(parseInt(seek))
    }

  };


  calcOffsetSeconds = (currentTime, duration, audioWidth) => {
    let offset = Math.max(0, currentTime - (audioWidth / 2))
    if (offset + audioWidth > duration) {
      offset = duration - audioWidth
    }
    if (offset < 0) {
      offset = 0
    }
    return offset
  }
  render() {
    return (
      <Animation
        className={this.props.className}
        height={this.props.height}
        width={this.props.width}
        dataProvider={this.props.getAudioData}
        onClick={evt => this.onCanvasClick(evt)}
        draw={this.draw}
      />
    );
  }
}

export default Waveform;
