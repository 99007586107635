import { ReduxAction } from "../actionModel";
import { Mission } from "../missionsModel";

export const MISSIONS_INIT = "[missions] init";
export const MISSIONS_FETCH = "[missions] fetch";
export const MISSIONS_FETCH_SUCCESS = "[missions] fetch success";
export const MISSIONS_FETCH_ERROR = "[missions] fetch error";
export const MISSIONS_SET = "[missions] set";

export const MISSIONS_CREATE_MISSION = "[missions] create mission";


export interface MissionsInitAction extends ReduxAction {
}

export interface SetMissionsAction extends ReduxAction {
    payload: { missions: Mission[] }
}

export const missionsInit = (): MissionsInitAction => ({
    type: MISSIONS_INIT
})

export const fetchMissions = (): ReduxAction => ({
    type: MISSIONS_FETCH
})

export const setMissions = (missions: Mission[]): ReduxAction => ({
    type: MISSIONS_SET,
    payload: { missions }
})

export interface CreateMissionAction extends ReduxAction {
    type: typeof MISSIONS_CREATE_MISSION,
    payload: { name: string }
}

export const createMission = (name: string): CreateMissionAction => ({
    type: MISSIONS_CREATE_MISSION,
    payload: { name }
})