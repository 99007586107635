import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { audioFileEditInit, audioFileRequestAddObservation, audioFileUnmount } from "../redux/audioFileEdit/audioFileEditActions"
import { AppState } from "../redux/reducers"
import AudioComponent from "../components/audio/AudioComponent"
import { Grid, Paper, Tab, Tabs, Typography } from "@mui/material"
import AudioFileEditMetadata from "../components/audioFileEdit/AudioFileEditMetadata"
import AudioFileEditObservations from "../components/audioFileEdit/AudioFileObservationsTab"


const AudioFileEditPage = () => {
    const [selectedTab, setSelectedTab] = useState(0)
    const param = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        missionId,
        audioFile,
        metadataShadow,
        url,
        observations
    } = useSelector((state: AppState) => state.audioFileEdit)

    const audioRef = useRef<HTMLAudioElement | null>(null)

    useEffect(() => {
        const { missionId, audiofileId } = param
        if (!missionId || !audiofileId) {
            console.log({ param })
            navigate('/')        
        } else {
            dispatch(audioFileEditInit(missionId, audiofileId))
        }
        return () => { dispatch(audioFileUnmount()) }
    }, [param, dispatch, navigate])

    useEffect(() => {
        setInterval(() => {
            if (audioRef.current) {
//                console.log('interval: currenttime', audioRef.current.currentTime)
            }
        }, 2000)
    }, [audioRef])

    //    console.log({audioFiles, audioFileId: param.audiofileId})
    if (!audioFile || !missionId) {
        return <Typography>Loading...</Typography>
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={12} item>
                <Typography variant="h5" sx={{ marginTop: 1 }}>{audioFile?.filename || 'Hello?'}</Typography>
            </Grid>
            <Grid xs={12} item>
                <AudioComponent ref={audioRef} url={url} />
            </Grid>
            <Grid xs={12} item>
                <Tabs value={selectedTab} onChange={(evt, idx) => { setSelectedTab(idx); console.log({ evt, idx }) }}>
                    <Tab sx={{ width: '50%'}} value={0} label="Metadata" />
                    <Tab sx={{ width: '50%'}} value={1} label="Observations" />
                </Tabs>
            </Grid>
            <Grid xs={12} item>
                <Paper>
                {selectedTab === 0 && <AudioFileEditMetadata metadata={audioFile?.metadata} shadow={metadataShadow} />}
                {selectedTab === 1 && <AudioFileEditObservations 
                    missionId={missionId}
                    audioFileId={audioFile.id}
                    onObservationAdded={(observation) => dispatch(audioFileRequestAddObservation(missionId, audioFile.id, observation))}
                    audioTimeProvider={() => audioRef.current ? audioRef.current.currentTime : undefined} 
                    metadata={audioFile?.metadata} 
                    observations={ observations } />} 
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AudioFileEditPage