export const formatSeconds = (seconds: number) : string => {
    if (isNaN(seconds)) {
        return ''
    }
    const hours = Math.floor(seconds / 3600)
    const remainingMinutes = seconds % 3600
    const minutes = Math.floor(remainingMinutes / 60)

    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`

    const remainingSeconds = seconds % 60
    const secondsStr = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`
    if (hours === 0) {
        return `${minutes}:${secondsStr}`
    }
    return `${hours}:${minutesStr}:${secondsStr}`
}
