import { select, takeLeading, put, apply, takeEvery } from '@redux-saga/core/effects'

import { loading, showMessage, stopLoading } from '../ui/uiActions'
import flydataAudioApi from '../../services/flydataAudioApi'
import { DeleteAudioFileRequestAction, MISSION_EDIT_DELETE_AUDIO_FILE_REQUEST, MISSION_EDIT_FETCH, MISSION_EDIT_INIT, MISSION_EDIT_STORE_META, MISSION_EDIT_UPDATE_SHADOW_META, MissionFetchAction, MissionInitAction, MissionStoreMetaAction, MissionUpdateShadowMetaAction, fetchMission, setMission, setShadowMeta } from './missionEditActions'
import { Mission, MissionMetadata } from '../missionsModel'
import { produce } from 'immer'
import { AppState } from '../reducers'
import { setSecondaryMenu } from '../app/appActions'


function* processInit(action: MissionInitAction) {
  try {
    const id = action.payload.id
    yield put(fetchMission(id))
  } catch (error: any) {
    try {
      console.log(error)
      //yield put(showMessage({ title: 'Error fetching missions', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processFetch(action: MissionFetchAction) {
  try {
    yield put(loading())
    const id = action.payload.id
    const response : { version: string, item: Mission } = yield apply (flydataAudioApi, flydataAudioApi.fetchMission, [id])
    yield put(setMission(response.item))
    yield put(setShadowMeta(response.item.metadata))
    yield put(setSecondaryMenu([ { title: `${response.item.name}`, icon: 'upload', path: `/missions/${id}`} ]))
    yield put(stopLoading())
  } catch (error: any) {
    try {
      console.log(error)
      //yield put(showMessage({ title: 'Error fetching missions', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processUpdateShadowMeta(action: MissionUpdateShadowMetaAction) {
  try {
    const { prop, value } = action.payload
    let shadow: MissionMetadata | undefined = yield select((state: AppState) => state.missionEdit.shadowMetadata)
    
    console.log({shadow, prop, value})
    let newShadow: MissionMetadata | undefined = produce(shadow, (draft) => {
      //@ts-expect-error
      draft[prop] = value
    })
    console.log({newShadow})
    yield put(setShadowMeta(newShadow))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error updating mission', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processStoreMetadata(action: MissionStoreMetaAction) {  
  try {
    const { missionId, metadata } = action.payload
    yield put(loading())
    const response : { appVersion : string, item: Mission } = yield apply(flydataAudioApi, flydataAudioApi.updateMissionMetadata, [missionId, metadata])
    console.log({response})
    //const response : { appVersion: string, mission: Mission } = yield apply (flydataAudioApi, flydataAudioApi.fetchMission, [missionId])
    yield put(setMission(response.item))
    yield put(setShadowMeta(response.item.metadata))
    yield put(stopLoading())
  } catch (error : any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error storing mission', text: error?.message }))
      yield put(stopLoading())
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processDeleteAudioFileRequest(action: DeleteAudioFileRequestAction) {
  try {
    const { missionId, audioFileId } = action.payload
    yield put(loading())
    const success : boolean = yield apply (flydataAudioApi, flydataAudioApi.deleteAudioFile, [missionId, audioFileId])
    if (!success) {
      yield put(showMessage({ title: 'Error deleting audio file', text: "Audio files can only be deleted if they don't have observations" }))      
    }
    yield put(stopLoading())
    yield put(fetchMission(missionId))    
  } catch (error: any) {
    try {
      console.log(error)
      yield put(stopLoading())
      yield put(showMessage({ title: 'Error deleting audio file', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

export default function* watcher() {
  yield takeLeading(MISSION_EDIT_INIT, processInit)  
  yield takeEvery(MISSION_EDIT_FETCH, processFetch)
  yield takeEvery(MISSION_EDIT_UPDATE_SHADOW_META, processUpdateShadowMeta)
  yield takeEvery(MISSION_EDIT_STORE_META, processStoreMetadata)
  yield takeEvery(MISSION_EDIT_DELETE_AUDIO_FILE_REQUEST, processDeleteAudioFileRequest) 
}
