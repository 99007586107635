import { Grid } from "@mui/material"
import { AudioFileMetadata } from "../../redux/missionsModel"
import EditComponent, { DateTimeFieldEdit, TextFieldEdit, TimeStampEditField } from "../edit/EditComponent"
import deepEqual from "deep-equal"
import { useDispatch } from "react-redux"
import { setAudioFileShadowMetadata, storeAudioFileMetadata, updateAudioFileShadowMetadata } from "../../redux/audioFileEdit/audioFileEditActions"

export interface AudioFileEditMetadataProps {
    metadata?: AudioFileMetadata
    shadow?: AudioFileMetadata
}

const AudioFileEditMetadata = (props: AudioFileEditMetadataProps) => {
    const { metadata, shadow } = props
    const dispatch = useDispatch()
    if (!metadata || !shadow) {
        return null
    }

    const dirty = !deepEqual(metadata, shadow)

    return (
        <Grid item xs={12}>
            <EditComponent 
                title="Metadata" 
                item={shadow} 
                dirty={dirty}
                onChange={(source, value) => dispatch(updateAudioFileShadowMetadata(source, value))}
                save
                cancel
                onSave={() => dispatch(storeAudioFileMetadata(shadow))}
                onCancel={() => dispatch(setAudioFileShadowMetadata(metadata))}
                >
                <TimeStampEditField title='Start time (UTC)' source={'startTime'} unit='ms' />
                <DateTimeFieldEdit title='Start time' source={'startTime'} unit='ms' readonly />
                <TextFieldEdit title='Observer' source={'observer'}  />
                <TextFieldEdit title='Observer side' source={'observerSide'}  />
            </EditComponent>
        </Grid>
    )
}

export default AudioFileEditMetadata