import { takeEvery, takeLeading, put, apply} from '@redux-saga/core/effects'

import {
  APP_REQUEST_LOGIN,
  RequestLoginAction,
  setUser,
  setLoggedOut,
  APP_REQUEST_LOGOUT,
  APP_INIT_AUTH,
  setChallenge,
  APP_FORGOT_PASSWORD,
  ForgotPasswordSubmitAction,
  APP_FORGOT_PASSWORD_SUBMIT,
  requestLogout,
  APP_INIT,
  setApiVersion,
  resetChallenge,
  APP_REQUEST_NEW_PASSWORD,
  RequestNewPasswordAction,
  requestLogin,
} from './appActions'
import { User, UserState } from './appReducer'
import { showMessage } from '../ui/uiActions'
import { AUTH_ERROR } from '../api/apiActions'
import { ReduxAction } from '../actionModel'
import AuthService from '../../services/authService'

function* processInit(action: ReduxAction) {
  try {
    const version = '0.0.1'
    yield put(setApiVersion(version))
    console.log('faked version', version)
  } catch (error) {
    console.log(error)
  }
}

function* processRequestLogin(action: RequestLoginAction) {
  try {
    const { username, password } = action.payload

    //const newPasswordChallenge : ChallengeName = 'NEW_PASSWORD_REQUIRED'

    const loginResult: UserState = yield apply(AuthService, AuthService.login, [username, password])
    console.log({ result: loginResult })

    if (loginResult.challenge === 'PASSWORD_RESET_REQUIRED') {
      yield apply(AuthService, AuthService.forgotPassword, [username])
      yield put(setChallenge({ username }, 'FORGOT_PASSWORD', null))
    } else if (loginResult.challenge === 'NEW_PASSWORD_REQUIRED') {
        yield put(setChallenge({ username }, 'NEW_PASSWORD_REQUIRED', null))        
    } else if (loginResult.user) {
      yield put(setUser(loginResult.user))
    } else {
      yield put(showMessage({ title: 'Error in login', text: 'Login failed' }))
    }
  } catch (error: any) {
    try {
      if (error && error.message) {
        yield put(showMessage({ title: 'Error', text: '' + error.message }))
      } else {
        console.log(error)
      }
      yield put(setLoggedOut())
    } catch (error) {}
  }
}

function* processInitAuth(action: ReduxAction) {
  try {
    const currentUser: User = yield apply(AuthService, AuthService.getCurrentUser, [])
    if (currentUser) {
      yield put(setUser(currentUser))
    } else {
      yield put(setLoggedOut())
    }
  } catch (error: any) {
    try {
      if (error && error.message) {
        yield put(showMessage({ title: 'Error', text: '' + error.message }))
      } else {
        console.log(error)
      }
      yield put(setLoggedOut())
    } catch (error) {}
  }
}

function* processRequestLogout(action: ReduxAction) {
  try {
    yield apply(AuthService, AuthService.logout, [])
    yield put(setLoggedOut())
  } catch (error: any) {
    try {
      if (error && error.message) {
        yield put(showMessage({ title: 'Logout failed', text: '' + error.message }))
      } else {
        console.log(error)
      }
      yield put(setLoggedOut())
    } catch (error) {}
  }
}

/*
const parseJwt = function(jwtToken : string) : any {
    try {
        const tokenContent = jwtToken.split('.')[1]
        return JSON.parse(Base64.decode(tokenContent))
    } catch (error) {
        console.log('error parsing jwtToken', jwtToken, error)
        return null
    }   
}*/

function* processForgotPassword(action: ReduxAction) {
  try {
    console.log('forgot password saga')
    const username = action.payload as string
    if (!username || username === '') {
      yield put(showMessage({ title: 'Username required', text: 'Please enter username and try again' }))
      return
    }
    yield apply(AuthService, AuthService.forgotPassword,[ username ])
    yield put(setChallenge({ username }, 'FORGOT_PASSWORD', null))
  } catch (error) {
    try {
      yield put(showMessage({ title: 'Fejl', text: '' + error }))
    } catch (error) {
      console.error(error)
    }
  }
}

function* processRequestNewPassword(action: RequestNewPasswordAction) {
  try {
    const { username, password, name } = action.payload
    yield apply(AuthService, AuthService.completeNewPasswordChallenge, [ password, { name }])
    yield put(requestLogin(username, password))
  } catch (error) {
    try {
      yield put(showMessage({ title: 'Fejl', text: '' + error }))
    } catch (error) {
      console.error(error)
    }
  }
}

function* processForgotPasswordSubmit(action: ForgotPasswordSubmitAction) {
  try {
    const { username, password, code } = action.payload
    yield apply(AuthService, AuthService.resetPassword, [username, code, password])
    yield put(resetChallenge())
    yield put(showMessage({ title: 'Password reset', text: 'Password reset successfully' }))
  } catch (error) {
    try {
      yield put(showMessage({ title: 'Fejl', text: '' + error }))
    } catch (error) {
      console.error(error)
    }
  }
}

function* processAuthError(action: any) {
  try {
    yield put(showMessage({ title: 'Auth error', text: 'Authentication failed' }))
    yield put(requestLogout())
  } catch (error) {
    console.log(error)
  }
}

export default function* watcher() {
  yield takeLeading(APP_INIT_AUTH, processInitAuth)
  yield takeLeading(APP_INIT, processInit)
  yield takeEvery(AUTH_ERROR, processAuthError)
  yield takeLeading(APP_REQUEST_LOGIN, processRequestLogin)
  yield takeEvery(APP_REQUEST_LOGOUT, processRequestLogout)
  yield takeLeading(APP_FORGOT_PASSWORD, processForgotPassword)
  yield takeLeading(APP_FORGOT_PASSWORD_SUBMIT, processForgotPasswordSubmit)
  yield takeLeading(APP_REQUEST_NEW_PASSWORD, processRequestNewPassword)
}
