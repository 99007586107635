import { ReduxAction } from '../actionModel'
import { AudioFile, AudioFileMetadata, AudioFileResolution, Observation } from '../missionsModel'

export const AUDIO_FILE_SET_MISSION_ID = '[audiofile] set mission id'

export const AUDIO_FILE_EDIT_INIT = '[audiofile] init'
export const AUDIO_FILE_EDIT_UNMOUNT = '[audiofile] unmount'

export const AUDIO_FILE_SET_AUDIO_FILE = '[audiofile] set audio file'
export const AUDIO_FILE_SET_URL = '[audiofile] set url'

export const AUDIO_FILE_FETCH_RESOLUTION_SUMMARY = '[audiofile] fetch resolution summary'

export const AUDIO_FILE_SET_RESOLUTION_SUMMARY = '[audiofile] set resolution summary'

export const AUDIO_FILE_CLEAR_RESOLUTION_SUMMARY = '[audiofile] clear resolution summary'

export const AUDIO_FILE_SET_SHADOW_METADATA = '[audiofile] set shadow metadata'
export const AUDIO_FILE_UPDATE_SHADOW_METADATA = '[audiofile] update shadow metadata'
export const AUDIO_FILE_STORE_METADATA = '[audiofile] store metadata'

export const AUDIO_FILE_FETCH_OBSERVATIONS = '[audiofile] fetch observations'
export const AUDIO_FILE_SET_OBSERVATIONS = '[audiofile] set observations'

export const AUDIO_FILE_REQUEST_ADD_OBSERVATION = '[audiofile] request add observation'

export const AUDIO_FILE_REQUEST_UPDATE_OBSERVATION = '[audiofile] request update observation'
export const AUDIO_FILE_DO_UPDATE_OBSERVATION = '[audiofile] do update observation'

export const AUDIO_FILE_REQUEST_DELETE_OBSERVATION = '[audiofile] request delete observation'
export const AUDIO_FILE_DO_DELETE_OBSERVATION = '[audiofile] do delete observation'

export interface AudioFileEditInitAction extends ReduxAction {
  type: typeof AUDIO_FILE_EDIT_INIT
  payload: { missionId: string; audioId: string }
}

export const audioFileEditInit = (missionId: string, audioId: string): AudioFileEditInitAction => ({
  type: AUDIO_FILE_EDIT_INIT,
  payload: { missionId, audioId },
})

export interface AudioFileSetUrlAction extends ReduxAction {
  type: typeof AUDIO_FILE_SET_URL
  payload: { url: string }
}

export const audioFileSetUrl = (url: string): AudioFileSetUrlAction => ({
  type: AUDIO_FILE_SET_URL,
  payload: { url },
})

export interface AudioFileFetchResolutionSummaryAction extends ReduxAction {
  type: typeof AUDIO_FILE_FETCH_RESOLUTION_SUMMARY
  payload: { missionId: string; audioId: string; resolution: AudioFileResolution }
}

export const audioFileFetchResolutionSummary = (
  missionId: string,
  audioId: string,
  resolution: AudioFileResolution
) => ({
  type: AUDIO_FILE_FETCH_RESOLUTION_SUMMARY,
  payload: { missionId, audioId, resolution },
})

export interface AudioFileSetResolutionSummaryAction extends ReduxAction {
  type: typeof AUDIO_FILE_SET_RESOLUTION_SUMMARY
  payload: { resolution: number; buckets: number[] }
}

export const setAudioFileResolutionSummary = (
  resolution: number,
  buckets: number[]
): AudioFileSetResolutionSummaryAction => ({
  type: AUDIO_FILE_SET_RESOLUTION_SUMMARY,
  payload: { resolution, buckets },
})

export const clearAudioFileResolutionSummary = (): ReduxAction => ({
  type: AUDIO_FILE_CLEAR_RESOLUTION_SUMMARY,
})

export interface AudioFileSetAudioFileAction extends ReduxAction {
  type: typeof AUDIO_FILE_SET_AUDIO_FILE
  payload: { audioFile: AudioFile }
}

export const setAudioFile = (audioFile: AudioFile): AudioFileSetAudioFileAction => ({
  type: AUDIO_FILE_SET_AUDIO_FILE,
  payload: { audioFile },
})

export interface AudioFileSetShadowMetadataAction extends ReduxAction {
  type: typeof AUDIO_FILE_SET_SHADOW_METADATA
  payload: { metadata?: AudioFileMetadata }
}

export const setAudioFileShadowMetadata = (metadata?: AudioFileMetadata): AudioFileSetShadowMetadataAction => ({
  type: AUDIO_FILE_SET_SHADOW_METADATA,
  payload: { metadata },
})

export interface AudioFileUpdateShadowMetadataAction extends ReduxAction {
  type: typeof AUDIO_FILE_UPDATE_SHADOW_METADATA
  payload: { prop: keyof AudioFileMetadata; value: any }
}

export const updateAudioFileShadowMetadata = (
  prop: keyof AudioFileMetadata,
  value: any
): AudioFileUpdateShadowMetadataAction => ({
  type: AUDIO_FILE_UPDATE_SHADOW_METADATA,
  payload: { prop, value },
})

export interface AudioFileStoreMetadataAction extends ReduxAction {
  type: typeof AUDIO_FILE_STORE_METADATA
  payload: { metadata: AudioFileMetadata }
}

export const storeAudioFileMetadata = (metadata: AudioFileMetadata): AudioFileStoreMetadataAction => ({
  type: AUDIO_FILE_STORE_METADATA,
  payload: { metadata },
})

export interface AudioFileFetchObservationsAction extends ReduxAction {
  type: typeof AUDIO_FILE_FETCH_OBSERVATIONS
  payload: { missionId: string; audioId: string }
}

export const audioFileFetchObservations = (missionId: string, audioId: string): AudioFileFetchObservationsAction => ({
  type: AUDIO_FILE_FETCH_OBSERVATIONS,
  payload: { missionId, audioId },
})

export interface AudioFileSetObservationsAction extends ReduxAction {
  type: typeof AUDIO_FILE_SET_OBSERVATIONS
  payload: { observations: Observation[] }
}

export const setAudioFileObservations = (observations: Observation[]): AudioFileSetObservationsAction => ({
  type: AUDIO_FILE_SET_OBSERVATIONS,
  payload: { observations },
})

export interface AudioFileRequestAddObservationAction extends ReduxAction {
  type: typeof AUDIO_FILE_REQUEST_ADD_OBSERVATION
  payload: {
    missionId: string
    audioFileId: string
    observation: Observation
  }
}

export const audioFileRequestAddObservation = (
  missionId: string,
  audioFileId: string,
  observation: Observation
): AudioFileRequestAddObservationAction => ({
  type: AUDIO_FILE_REQUEST_ADD_OBSERVATION,
  payload: {
    missionId,
    audioFileId,
    observation,
  }
})

export interface AudioFileRequestUpdateObservationAction extends ReduxAction {
  type: typeof AUDIO_FILE_REQUEST_UPDATE_OBSERVATION
  payload: {
    missionId: string
    audioFileId: string
    observation: Observation
  }
}

export const updateObservation = (missionId: string, audioFileId: string, observation: Observation): AudioFileRequestUpdateObservationAction => ({
  type: AUDIO_FILE_REQUEST_UPDATE_OBSERVATION,
  payload: {
    missionId,
    audioFileId,
    observation,
  }
})



export interface AudioFileDoUpdateObservationAction extends ReduxAction {
  type: typeof AUDIO_FILE_DO_UPDATE_OBSERVATION
  payload: {
    missionId: string
    audioFileId: string
    observation: Observation
  }
}

export const doUpdateObservation = (missionId: string, audioFileId: string, observation: Observation): AudioFileDoUpdateObservationAction => ({
  type: AUDIO_FILE_DO_UPDATE_OBSERVATION,
  payload: {
    missionId,
    audioFileId,
    observation,
  }
})


export interface AudioFileRequestDeleteObservationAction extends ReduxAction {
  type: typeof AUDIO_FILE_REQUEST_DELETE_OBSERVATION
  payload: {
    missionId: string
    audioFileId: string
    observationId: string
  }
}

export const deleteObservation = (missionId: string, audioFileId: string, observationId: string): AudioFileRequestDeleteObservationAction => ({
  type: AUDIO_FILE_REQUEST_DELETE_OBSERVATION,
  payload: {
    missionId,
    audioFileId,
    observationId,
  }
})

export interface AudioFileDoDeleteObservationAction extends ReduxAction {
  type: typeof AUDIO_FILE_DO_DELETE_OBSERVATION
  payload: {
    missionId: string
    audioFileId: string
    observationId: string
  }
}

export const doDeleteObservation = (missionId: string, audioFileId: string, observationId: string): AudioFileDoDeleteObservationAction => ({
  type: AUDIO_FILE_DO_DELETE_OBSERVATION,
  payload: {
    missionId,
    audioFileId,
    observationId,
  }
})

export const audioFileUnmount = (): ReduxAction => ({
  type: AUDIO_FILE_EDIT_UNMOUNT
})