import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'
import { User, UserState } from '../redux/app/appReducer'

// * global FLYDATA_AUDIO_WEB */
declare var FLYDATA_AUDIO_WEB: {
  api: string
  cognito: {
    userPoolId: string
    clientId: string
  }
}

class AuthService {
  pool: CognitoUserPool

  user: CognitoUser | null = null

  constructor() {   
    const poolData = {
      UserPoolId: FLYDATA_AUDIO_WEB.cognito.userPoolId,
      ClientId: FLYDATA_AUDIO_WEB.cognito.clientId
    }

    this.pool = new CognitoUserPool(poolData)
  }

  getCurrentUser = (): Promise<User | null> => {
    return new Promise((resolve, reject) => {
      const user: CognitoUser | null = this.pool.getCurrentUser()
      if (!user) {
        this.user = null
        resolve(null)
      } else {
        user.getSession((err : any, session : CognitoUserSession) => {
          this.user = user
        const username = user.getUsername()
        const token = session.getIdToken().getJwtToken()   
        const tokenPayloadBase64 = token.split('.')[1] // this is the payload of the token
        const tokenPayload = JSON.parse(atob(tokenPayloadBase64))
        const groups = tokenPayload['cognito:groups']        
        //user.getUserAttributes((error, result) => {console.log({ userattributes: result })})
        //user.getUserData((error, result) => {console.log({ userdata: result })})
        resolve({ username, token, groups })
        })
        
      }
    })
  }

  forgotPassword = (username: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const _user = new CognitoUser({
        Username: username,
        Pool: this.pool,
      })

      console.log('sending forgot password', username)
      _user.forgotPassword({
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  resetPassword = (username: string, code: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const _user = new CognitoUser({
        Username: username,
        Pool: this.pool,
      })
      console.log('sending forgot password', username)
      _user.confirmPassword(code, password, {
        onSuccess: () => {
          resolve()
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  login = (username: string, password: string): Promise<UserState> => {
    return new Promise((resolve, reject) => {
      const _user = new CognitoUser({
        Username: username,
        Pool: this.pool,
      })

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      })

      _user.authenticateUser(authDetails, {
        onSuccess: (data: CognitoUserSession) => {
          console.log('login ok', data)
          const token = data.getIdToken().getJwtToken()
          const user = {            
            username: data.getIdToken().payload['cognito:username'],
            token,
            groups: data.getIdToken().payload['cognito:groups']
          }
          console.log('login: got user', { user})
          resolve({ user })
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.log({ userAttributes, requiredAttributes })
          this.user = _user
          resolve({ user: null, challenge: 'NEW_PASSWORD_REQUIRED' })
        },
        onFailure: (err) => {
          if (err.code === 'PasswordResetRequiredException') {
            resolve({ user: null, challenge: 'PASSWORD_RESET_REQUIRED' })
          } else {
            console.error('login error', err)
            reject(err)
          }
        },
      })
    })
  }

  completeNewPasswordChallenge = (password: string, requiredAttributes: Record<string, string>): Promise<UserState> => {
    return new Promise((resolve, reject) => {
      if (!this.user || this.user === null) {
        reject('No user to complete challenge')
      } else {
        this.user.completeNewPasswordChallenge(password, requiredAttributes, {
          onSuccess: (data: CognitoUserSession) => {
            console.log('login ok', data)
            const user = {
              username: data.getIdToken().payload['cognito:username'],
              token: data.getIdToken().getJwtToken(),
            }
            resolve({ user })
          },
          onFailure: (err) => {
            console.error('login error', err)
            reject(err)
          },
        })
      }
    })
  }

  logout = () => {
    const user = this.pool.getCurrentUser()
    user?.signOut()
  }
}

const instance = new AuthService()

export default instance
