import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AudioFileMetadata, Observation, ObservationType } from '../../redux/missionsModel'
import { useRef, useState } from 'react'

export interface AddObservationFormProps {
  metadata: AudioFileMetadata
  audioTimeProvider: () => number | undefined
  onObservationAdded: (observation: Observation) => void
}

const types: Record<string, ObservationType> = {
  TRANSECT: 'transect',
  TOTAL: 'total',
}

const fieldStyle = { width: 128, marginRight: 1 }

const AddObservationForm = ({ audioTimeProvider, onObservationAdded }: AddObservationFormProps) => {
  const [type, setType] = useState<ObservationType>(types.TRANSECT)
  const [observationType, setObservationType] = useState<ObservationType>('SPECIES')

  const [species, setSpecies] = useState<string>('')
  const [audioTime, setAudioTime] = useState<number>(0)
  const [count, setCount] = useState<string>('')
  const [distBand, setDistBand] = useState<string>('')
  const [age, setAge] = useState<string>('')
  const [behaviour, setBehaviour] = useState<string>('')
  const [sex, setSex] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  const speciesRef = useRef<HTMLInputElement>(null)
  const countRef = useRef<HTMLInputElement>(null)
  const distBandRef = useRef<HTMLInputElement>(null)
  const ageRef = useRef<HTMLInputElement>(null)
  const behaviourRef = useRef<HTMLInputElement>(null)
  const sexRef = useRef<HTMLInputElement>(null)
  const commentRef = useRef<HTMLInputElement>(null)

  const countLabel = observationType === 'SPECIES' ? 'Count' : 'Intensity'

  const handleSpeciesChanged = () => {
    const value = species
    //console.log('species changed', value)
    const _v = parseInt(value)
    if (isNaN(_v)) {
      console.log('Species must be a number')
      return
    }
    const tmpTime = audioTimeProvider()
    if (typeof tmpTime === 'undefined') {
      console.log('No audio time')
      return
    }
    const audioTime = ~~tmpTime
    setAudioTime(audioTime)
    //console.log('Audio time', audioTime)

    switch (_v) {
      case 800:
        speciesRef.current && speciesRef.current.focus()
        setSpecies('')
        onObservationAdded({ audioTime, observationType: 'TRANSECT_START' })
        break
      case 899:
        speciesRef.current && speciesRef.current.focus()
        setSpecies('')
        onObservationAdded({ audioTime, observationType: 'TRANSECT_END' })
        break
      case 850:
        setObservationType('SEA_STATE_CHANGE')
        break
      case 860:
        setObservationType('SUN_INTENSITY_CHANGE')
        break
      default:
        setObservationType('SPECIES')
        break
    }
  }

  const handleCountFocusLost = (value: string, commit: boolean) => {
    if (commit) {
      if (observationType === 'SPECIES') {
        const _v = parseInt(value)
        if (isNaN(_v)) {
          console.log('Count must be a number')
          return
        }
        onObservationAdded({ species, audioTime, observationType: type, count: _v })
      } else {
        const _v = parseFloat(value)
        console.log(`Intensity parsed: ${value} -> ${_v}`)
        onObservationAdded({ audioTime, observationType: observationType, intensity: _v })
      }
      speciesRef.current && speciesRef.current.focus()
      setSpecies('')
      setCount('')
    } else {
      setCount(value)
    }
  }

  const commit = () => {
    const obstype: ObservationType = observationType === 'SPECIES' ? type : observationType
    const obs: Observation = {
      species,
      observationType: obstype,
      audioTime,
      count: count,
      distanceBand: distBand,
      age,
      behaviour,
      sex,
      comment,
    }
    onObservationAdded(obs)
    speciesRef.current && speciesRef.current.focus()
    setSpecies('')
    setCount('')
    setAge('')
    setBehaviour('')
    setSex('')
    setComment('')
    setDistBand('')
  }

  return (
    <>
      <Grid container spacing={1} sx={{ marginLeft: 1, marginBottom: 1 }}>
        <Grid item xs={12}>
          <FormControl margin="normal" style={{ width: '100%', maxWidth: '250px' }}>
            <InputLabel htmlFor="observation-type">Observation type</InputLabel>
            <Select
              id="observation-type"
              sx={{ marginTop: 1 }}
              value={type}
              onChange={(evt) => {
                setType(evt.target.value as ObservationType)
              }}
            >
              <MenuItem key={types.TRANSECT} value={types.TRANSECT}>
                {types.TRANSECT.toUpperCase()}
              </MenuItem>
              <MenuItem key={types.TOTAL} value={types.TOTAL}>
                {types.TOTAL.toUpperCase()}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Koder: 800 = Transect start, 899 = Transect slut, 850 = Sea state change, 860 = Sun intensity change
          </Typography>
        </Grid>
        <Grid sx={{ backgroundColor: '#eeeeee', paddingBottom: 1, marginRight: 3 }} item xs={12}>
          <TextField
            inputRef={speciesRef}
            sx={fieldStyle}
            label="Species"
            size="small"
            value={species}
            onChange={(evt) => setSpecies(evt.target.value)}
            onKeyDown={(evt) => {
              if (evt.key === 'Tab') {
                handleSpeciesChanged()
              }
            }}
            onKeyUp={(evt) => {
              if (evt.key === 'Enter') {
                countRef.current && countRef.current.focus()
                handleSpeciesChanged()
              }
            }}
          ></TextField>
          <TextField
            value={count}
            type='number'
            onChange={(evt) => setCount(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && handleCountFocusLost(count, true)}
            inputRef={countRef}
            sx={fieldStyle}
            label={countLabel}
            size="small"
          ></TextField>
            <TextField
            inputRef={behaviourRef}
            value={behaviour}
            onChange={(evt) => setBehaviour(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && commit()}
            sx={fieldStyle}
            label="Behaviour"
            size="small"
          />
          <TextField
            inputRef={distBandRef}
            value={distBand}
            onChange={(evt) => setDistBand(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && commit()}
            sx={fieldStyle}
            label="Distance band"
            size="small"
          />
          <TextField
            inputRef={ageRef}
            value={age}
            onChange={(evt) => setAge(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && commit()}
            sx={fieldStyle}
            label="Age"
            size="small"
          />
        
          <TextField
            inputRef={sexRef}
            value={sex}
            onChange={(evt) => setSex(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && commit()}
            sx={fieldStyle}
            label="Sex"
            size="small"
          />
          <TextField
            inputRef={commentRef}
            value={comment}
            onChange={(evt) => setComment(evt.target.value)}
            onKeyUp={(evt) => evt.key === 'Enter' && commit()}
            sx={fieldStyle}
            label="Comment"
            size="small"
          />
          <TextField
            sx={fieldStyle}
            label="Audio time"
            size="small"
            value={audioTime}
            onChange={(evt) => setAudioTime(parseInt(evt.target.value))}
            onKeyUp={(evt) => evt.key === 'Enter' && commit()}
          />
        </Grid>        
      </Grid>
    </>
  )
}

export default AddObservationForm
