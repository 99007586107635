
import { MISSION_EDIT_SET, MISSION_EDIT_SET_SHADOW_META, MissionSetAction, MissionSetShadowMetaAction } from './missionEditActions';
import { Mission, MissionMetadata } from '../missionsModel';


export interface MissionEditStore {
    mission?:  Mission
    shadowMetadata?: MissionMetadata
}

const initialState: MissionEditStore = {  
}

const missionEdit = (state = initialState, action: any) : MissionEditStore => {
  switch (action.type) {    
    case MISSION_EDIT_SET_SHADOW_META:
      const setShadowMetaAction = action as MissionSetShadowMetaAction
      return { ...state, shadowMetadata: setShadowMetaAction.payload.metadata }
    case MISSION_EDIT_SET:
        const setMissionAction = action as MissionSetAction
        console.log('setMission', { payload: setMissionAction.payload })
        return { ...state, mission: setMissionAction.payload.mission}
    default:
      return state
  }
}

export default missionEdit
