const getData = async (response: any) : Promise<any> => {
    try {
        return await response.json()
    } catch (error) {
        return {}
    }
}

const call = async (url : string, config : any) : Promise<any> => {
    //console.log('calling', url, config)
    const response = await fetch(url, config)
    try {
        const data = await getData(response)
        if (response.status >= 400) {
            return Promise.reject({
                status: response.status,
                error: data
            })
        }
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject({
            status: response.status,
            error
        })
    }
}

const callText = async (url : string, config : any) : Promise<any> => {
    const response = await fetch(url, config)
    try {
        const data = await response.text()
        if (response.status >= 400) {
            return Promise.reject({
                status: response.status,
                error: data
            })
        }
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject({
            status: response.status,
            error
        })
    }
}

const getConfig = async (method: string, getToken: () => Promise<string | null |undefined>, i : number = 0) : Promise<Record<string, any>> => {
    if (i > 50) {
        console.log('no config!')
        return Promise.reject('Cannot return config, no token')
    }
    const token = await getToken()
    if (token) {
        return Promise.resolve({
            method,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    } else {
        return Promise.resolve({
            method          
        })
    }
}

const getPostConfig = async (body: any, getToken: any) : Promise<any>  => {
    const config : Record<string, any> = await getConfig('POST', getToken)
    config.headers['Content-Type'] = 'application/json'
    config.body = JSON.stringify(body)   
    return Promise.resolve(config)
}

const getDeleteConfig = async (getToken: any) : Promise<any> => {
    const config = await getConfig('DELETE', getToken)
    return Promise.resolve(config)
}

export const queryOptionsToParams = (queryOptions: Record<string, string | number | boolean> | null) => {
    if (queryOptions == null) {
        return '';
    }
    let parameters = [];
    for (const [key, value] of Object.entries(queryOptions)) {
        if (value && value !== null) {
            parameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        } else  if (value === false) {
            parameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        }
    }
    if (parameters.length === 0) {
        return '';
    }
    return '?' + parameters.join('&');
}

export const getText = async (baseUrl: string, url: string, queryOptions: Record<string, string | number> | null, getToken: any) => {
    const config = await getConfig('GET', getToken)
    //console.log()
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return callText(_url, config)
}

export const get = async (baseUrl: string, url: string, queryOptions: Record<string, string | number | boolean> | null, getToken: any) => {
    const config = await getConfig('GET', getToken)
    //console.log()
    const _url = baseUrl + url + queryOptionsToParams(queryOptions)
    return call(_url, config)
}

export const put = async (baseUrl: string, url: string, body: (Record<string, any> | null), getToken: () => Promise<string | null | undefined>) => {
    const config = await getConfig('PUT', getToken)
    if (body) {
        config.body = JSON.stringify(body)
        config.headers['Content-Type'] = 'application/json'
    }
    const _url = baseUrl + url
    return call(_url, config)
}

export const post = async (baseUrl: string, url: string, body: any, getToken: any) => {
    const config = await getPostConfig(body, getToken) 
    const _url = baseUrl + url
    return call(_url, config)
}

export const del = async (baseUrl: string, url: string, getToken: any) => {
    const config = await getDeleteConfig(getToken) 
    const _url = baseUrl + url
    return call(_url, config)
}