import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StaticDataList, {
  TextFieldCell,
  UnixDateOnlyCell,
  UnixTimestampCell,
} from '../components/datalist/StaticDataList'
import { AppState } from '../redux/reducers'
import { createMission, missionsInit } from '../redux/missionList/missionsActions'
import { useNavigate } from 'react-router-dom'
import { Button, IconButton, Paper, TextField, Typography } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MissionListPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const missions = useSelector((state: AppState) => state.missions.missions)
  const [name, setName] = useState('')

  const [ currentPage, setCurrentPage ] = useState(1)
  

  useEffect(() => {
    dispatch(missionsInit())
  }, [dispatch])

  const pageSize = 20
  const pages = Math.ceil(missions.length / pageSize) || 1

  return (
    <>
      <StaticDataList
        onClick={(mission) => {
          navigate(`/missions/${mission.id}`)
        }}
        items={missions}
        visible={true}
        dense
        loading={false}
        limit={pageSize}
        offset={(currentPage - 1) * pageSize}
      >
        <TextFieldCell title={'Navn'} source={'name'} />
        <UnixDateOnlyCell title={'Oprettet'} source={'created'} unit="ms" />
        <UnixTimestampCell title={'Ændret'} source={'lastupdated'} unit="ms" />
        <TextFieldCell title={'Oprettet af'} source={'createdBy'} />
        <TextFieldCell title={'Callsign'} source={'metadata.callsign'} />
      </StaticDataList>
      <IconButton disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)}><ChevronLeftIcon /></IconButton>
        {` ${currentPage} / ${pages}`}
        <IconButton disabled={currentPage >= pages} onClick={() => setCurrentPage(currentPage + 1)}><ChevronRightIcon /></IconButton>
      <Paper sx={{ padding: 1, marginTop: 2, maxWidth: 640 }} elevation={4}>
        <Typography sx={{ marginLeft: 1, display: 'block'}} variant='overline'>Create new mission</Typography>
        <TextField
          sx={{ marginRight: 1 }}
          label="New Mission name"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button disabled={name === ''} variant="contained" onClick={() => dispatch(createMission(name))}>
          Create mission
        </Button>
      </Paper>
    </>
  )
}

export default MissionListPage
