import { all } from 'redux-saga/effects'

import appWatcher from '../app/appSagas'
import missionsWatcher from '../missionList/missionsSagas'
import missionEditWatcher from '../missionEdit/missionEditSagas'    
import audioFileEditWatcher from '../audioFileEdit/audioFileEditSagas'

export default function* rootSaga() {
    yield all([
        appWatcher(),
        missionsWatcher(),
        missionEditWatcher(),
        audioFileEditWatcher()    
    ])
}