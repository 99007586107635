import { ReduxAction } from "../actionModel";
import { Mission, MissionMetadata } from "../missionsModel";

export const MISSION_EDIT_INIT = "[mission] init";
export const MISSION_EDIT_FETCH = "[mission] fetch";
export const MISSION_EDIT_SET = "[mission] set";
export const MISSION_EDIT_SET_SHADOW_META = "[mission] set shadow meta";
export const MISSION_EDIT_UPDATE_SHADOW_META = "[mission] update shadow meta";
export const MISSION_EDIT_STORE_META = "[mission] store meta";

export const MISSION_EDIT_UPLOAD_AUDIO_FILE = "[mission] upload audio file";
export const MISSION_EDIT_UPLOAD_AUDIO_FILE_SUCCESS = "[mission] upload audio file success";

export const MISSION_EDIT_DELETE_AUDIO_FILE_REQUEST = "[mission] delete audio file request";
export const MISSION_EDIT_DELETE_AUDIO_FILE_COMPLETE = "[mission] delete audio file complete";

export interface MissionInitAction extends ReduxAction {
    type: typeof MISSION_EDIT_INIT,
    payload: { id: string }
}

export interface MissionFetchAction extends ReduxAction {
    type: typeof MISSION_EDIT_FETCH,
    payload: { id: string }
}

export interface MissionSetAction extends ReduxAction {
    payload: { mission: Mission }
}

export interface MissionSetShadowMetaAction extends ReduxAction {
    payload: { metadata: MissionMetadata | undefined}
}

export interface MissionUpdateShadowMetaAction extends ReduxAction {
    payload: {
        prop: string,
        value: any
    }
}

export const setMission = (mission: Mission): MissionSetAction => ({
    type: MISSION_EDIT_SET,
    payload: { mission }
})

export const setShadowMeta = (metadata: MissionMetadata | undefined): MissionSetShadowMetaAction => ({
    type: MISSION_EDIT_SET_SHADOW_META,
    payload: { metadata }
})

export const initMissionEdit = (id: string): MissionInitAction => ({
    type: MISSION_EDIT_INIT,
    payload: { id }
})

export const fetchMission = (id: string): MissionFetchAction => ({
    type: MISSION_EDIT_FETCH,
    payload: { id }
})

export const updateShadowMeta = (prop: string, value: any): MissionUpdateShadowMetaAction => ({
    type: MISSION_EDIT_UPDATE_SHADOW_META,
    payload: { prop, value }
})

export interface MissionStoreMetaAction extends ReduxAction {
    type: typeof MISSION_EDIT_STORE_META,
    payload: {
        missionId: string,
        metadata: MissionMetadata
    }
}

export const storeMeta = (missionId: string, metadata: MissionMetadata): ReduxAction => ({
    type: MISSION_EDIT_STORE_META,
    payload: { missionId, metadata }
})

export interface MissionUploadAudioFileAction extends ReduxAction {
    type: typeof MISSION_EDIT_UPLOAD_AUDIO_FILE,
    payload: {
        missionId: string,
        name: string,
        file: File
    }
}

export const uploadAudioFile = (missionId: string, name: string, file: File): ReduxAction => ({
    type: MISSION_EDIT_UPLOAD_AUDIO_FILE,
    payload: { missionId, name, file }
})

export interface DeleteAudioFileRequestAction extends ReduxAction {
    type: typeof MISSION_EDIT_DELETE_AUDIO_FILE_REQUEST,
    payload: {
        missionId: string,
        audioFileId: string
    }
}

export const deleteAudioFile = (missionId: string, audioFileId: string): DeleteAudioFileRequestAction => ({
    type: MISSION_EDIT_DELETE_AUDIO_FILE_REQUEST,
    payload: { missionId, audioFileId }
})