import React from "react";
import PureCanvas from "./PureCanvas";

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.saveContext = this.saveContext.bind(this);
  }

  componentDidMount() {
    this.props.draw(this.ctx, this.getData(), this.width, this.height);
  }

  saveContext(ctx) {
    this.ctx = ctx;
    this.width = this.ctx.canvas.width;
    this.height = this.ctx.canvas.height;
  }

  componentDidUpdate() {
    this.props.draw(this.ctx, this.props.data, this.width, this.height, this.props.fps);
  }

  getData(props) {
    if (this.props.data && this.props.data.data) {
      return this.props.data.data;
    }
    return this.props.data;
  }

  render() {
    return (
      <PureCanvas
        className={this.props.className}
        width={this.props.width}
        height={this.props.height}
        contextRef={this.saveContext}
        onClick={this.props.onClick}
      />
    );
  }
}

export default Canvas;
