import { AudioFile, AudioFileMetadata, AudioFileSummary, Observation } from '../missionsModel';
import { AUDIO_FILE_CLEAR_RESOLUTION_SUMMARY, AUDIO_FILE_EDIT_INIT, AUDIO_FILE_EDIT_UNMOUNT, AUDIO_FILE_SET_AUDIO_FILE, AUDIO_FILE_SET_MISSION_ID, AUDIO_FILE_SET_OBSERVATIONS, AUDIO_FILE_SET_RESOLUTION_SUMMARY, AUDIO_FILE_SET_SHADOW_METADATA, AUDIO_FILE_SET_URL, AudioFileSetAudioFileAction, AudioFileSetObservationsAction, AudioFileSetResolutionSummaryAction, AudioFileSetShadowMetadataAction } from './audioFileEditActions';

export interface AudioFileEditStore {
  missionId?: string
  audioFile?: AudioFile
  metadataShadow?: AudioFileMetadata
  summary?: AudioFileSummary
  url?: string
  resolutionSummary?: Record<string, number[]>
  observations: Observation[]
  mounted: boolean
}

const initialState: AudioFileEditStore = {
  observations: [],
  mounted: false
}

const audioFileEdit = (state = initialState, action: any): AudioFileEditStore => {
  console.log('audioFileEdit', action.type)
  switch (action.type) {
    case AUDIO_FILE_EDIT_INIT:
      return { ...state, mounted: true}
    case AUDIO_FILE_EDIT_UNMOUNT:
      return { ...initialState }
    case AUDIO_FILE_SET_MISSION_ID:
      console.log('setting missionid: ', action.payload)
      return { ...state, missionId: action.payload.missionId }
    case AUDIO_FILE_SET_OBSERVATIONS:
      const setObservationsAction = action as AudioFileSetObservationsAction
      const obs = setObservationsAction.payload.observations
      obs.sort((a, b) => b.audioTime - a.audioTime)
      return { ...state, observations:  obs}
    case AUDIO_FILE_SET_AUDIO_FILE:
      const setAudioFileAction = action as AudioFileSetAudioFileAction
      return { ...state, audioFile: setAudioFileAction.payload.audioFile }
    case AUDIO_FILE_CLEAR_RESOLUTION_SUMMARY:
      return { ...state, resolutionSummary: {} }
    case AUDIO_FILE_SET_RESOLUTION_SUMMARY:
      const setResolutionAction = action as AudioFileSetResolutionSummaryAction
      return { ...state, resolutionSummary: { ...state.resolutionSummary, [`${setResolutionAction.payload.resolution}`]: setResolutionAction.payload.buckets } }
    case AUDIO_FILE_SET_URL:
      return { ...state, url: action.payload.url }
    case AUDIO_FILE_SET_SHADOW_METADATA:
      const setShadowAction = action as AudioFileSetShadowMetadataAction
      return { ...state, metadataShadow: setShadowAction.payload.metadata }
    default:
      return state
  }
}

export default audioFileEdit
