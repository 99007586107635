import React, { useState } from "react";

import { Button, Chip, TextField } from "@mui/material";

import { EditComponentChild } from "./EditComponent";

const createValue = (prop: (string | undefined), value: string) => {
  if (prop) {
    return { [prop]: value };
  }
  return value;
}

export const StringListFieldEdit = (props: any): EditComponentChild => {
  const [showNew, setShowNew] = useState(false);
  const [newValue, setNewValue] = useState("");

  const [ editIndex, setEditIndex ] = useState<number | null>(null)
  
  const { item, source, prop, onChange } = props;
  const value: any[] = item[source] || [];
  
  console.log({ item, value, source, editIndex})

  return (
    <div>
      { editIndex !== null && (
          <div>
          <TextField
            size='small'
            variant='standard'
            value={newValue}
            onChange={(e: any) => setNewValue(e.target.value)}
          />
          <Button
            disabled={!newValue}
            size='small'
            color='primary'
            onClick={() => {              
              onChange(value.map((e: any, idx: number) => idx === editIndex ? createValue(prop, newValue) : e));
              setEditIndex(null);
              setNewValue("");
            }}
          >
            Add
          </Button>
          <Button
            size='small'
            color='inherit'
            onClick={() => {
              setEditIndex(null);
              setNewValue("");
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      {showNew && (
        <div>
          <TextField
            size='small'
            variant='standard'
            value={newValue}
            onChange={(e: any) => setNewValue(e.target.value)}
          />
          <Button
            disabled={!newValue}
            size='small'
            color='primary'
            onClick={() => {
              const tmp = value.concat(createValue(prop, newValue))            
              onChange(tmp);
              setShowNew(false);
              setNewValue("");
            }}
          >
            Add
          </Button>
          <Button
            size='small'
            color='inherit'
            onClick={() => {
              setShowNew(false);
              setNewValue("");
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      { !(showNew || editIndex !== null) ?
        <>
            {value.map((e: any, idx: number) => (
                <Chip
                    size='small'
                    key={idx}
                    label={prop ? e[prop] : e}
                    color='primary'
                    clickable
                    onClick={() => { setEditIndex(idx); setNewValue(prop ? e[prop] : e)}}
                    onDelete={() => onChange(value.filter((i: any) => i !== e))}
                />
            ))}
            <Chip size='small' label="+" clickable onClick={() => setShowNew(true)} />
        </> : null
      }
    </div>
  );
};
