import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import UploadButton from './UploadButton'
import flydataAudioApi from '../services/flydataAudioApi'
import { fetchMission } from '../redux/missionEdit/missionEditActions'
import { showMessage } from '../redux/ui/uiActions'

export interface UploadAudioComponentProps {
  missionId: string
}

type UploadState = 'uploading' | 'done' | 'failed' | null

const xhrUpload = async (url: string, file: File, onProgress: (progress: number) => void): Promise<boolean> => {
  const xhr = new XMLHttpRequest()
  return new Promise((resolve) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        onProgress(100 * (event.loaded / event.total))
      }
    })

    xhr.addEventListener('loadend', () => {
      resolve(xhr.readyState === 4 && xhr.status === 200)
    })
    xhr.open('PUT', url, true)
    //xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.send(file)
  })
}

const UploadAudioComponent = ({ missionId }: UploadAudioComponentProps) => {
  const [name, setName] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)
  const [uploadState, setUploadState] = useState<UploadState>(null)
  const dispatch = useDispatch()

  const doUpload = async (name: string, file: File) => {
    const response = await flydataAudioApi.addAudio(missionId, { filename: file.name, name })
    const item = response.item

    setUploadState('uploading')

    try {
      const success = await xhrUpload(item.uploadUrl, file, (progress) => setUploadProgress(progress))
      if (success) {
        setUploadState('done')
        setUploadProgress(null)
        setShowUploadDialog(false)
        dispatch(fetchMission(missionId))
      }
    } catch (error: any) {
      dispatch(showMessage({ title: 'Upload failed', text: error.message }))
      setUploadState('done')
      setUploadProgress(null)
      setShowUploadDialog(false)
    }
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setShowUploadDialog(true)}>
        Add audio file
      </Button>
      <Dialog open={showUploadDialog} onClose={() => setShowUploadDialog(false)}>
        <DialogTitle>Upload audio file</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {uploadState === 'uploading' ? (
              <>
                <Grid item xs={12}>
                  <TextField fullWidth label="Name" value={name} disabled />
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress variant="determinate" value={uploadProgress || 0} />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                  <UploadButton
                    color={file === null ? 'primary' : 'inherit'}
                    fullWidth
                    label="Select file"
                    onChange={(evt, files) => {
                      if (files && files.length > 0) {
                        if (!name || name.trim() === '') {
                          setName(files[0].name)
                        }
                        setFile(files[0])
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        {uploadState === 'uploading' || uploadState === 'done' ? null : (
          <DialogActions>
            <Button
              onClick={() => {
                setShowUploadDialog(false)
                setName('')
                setFile(null)
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginRight: 2, marginBottom: 1 }}
              variant="contained"
              disabled={file === null}
              onClick={() => {
                if (file === null) {
                  return
                }
                const _name = name.trim() === '' ? file.name : name
                doUpload(_name, file)
              }}
            >
              Upload
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default UploadAudioComponent
