import rootSaga from './sagas/rootSaga'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'   
import { Tuple, configureStore } from "@reduxjs/toolkit";




//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/*const composeEnhancers =  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
compose;*/

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: reducers,
    middleware: () => new Tuple(sagaMiddleware)
})


sagaMiddleware.run(rootSaga)

export default store;