import React, { useState } from "react";

import { Typography, TextField, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { forgotPasswordSubmit } from "../../redux/app/appActions";

/*
const useStyles = (theme: Theme) => makeStyles(() => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/1600x900/?nature,water)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));*/

const ForgotPasswordComponent = function ({ username }: { username: string }) {
  //const theme = useTheme()
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
//  const classes = useStyles(theme)();

  return (
    <React.Fragment>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <div>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          disabled
          autoComplete="username"
          value={username}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="code"
          label="Code"
          type="number"
          id="code"
          value={code}
          onChange={(evt) => setCode(evt.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
          autoComplete="current-password"
        />
        <Button
          onClick={() => {
            dispatch(forgotPasswordSubmit(username, code, password));
          }}
          fullWidth
          variant="contained"
          color="primary"          
        >
          Reset password
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordComponent;
