import React, { ReactElement } from "react"
import {
    Paper, Grid, Button, TextField,
    Typography
} from '@mui/material'

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import DelayedTextField from "./DelayedTextField"
import { DateTime } from "luxon"

export interface EditComponentChild extends ReactElement {

}

interface EditComponentProps<E> {
    disabled?: boolean;
    elevation?: number;
    title?: string;
    item: E | null;
    dirty?: boolean;
    save?: boolean;
    cancel?: boolean;
    onSave: () => void;
    onCancel?: any;
    onChange: (source: keyof E, value: any) => void;
    children: EditComponentChild | EditComponentChild[];
}


export const NumberFieldEdit = (props: any): EditComponentChild => {
    const { item, value, source, readonly, onChange } = props
    let disabled = readonly
    if (typeof readonly === 'undefined') {
        disabled = false
    }
    if (value || value === '') {
        return <DelayedTextField size='small' type='number' fullWidth value={value} disabled={true} />
    }
    const _value = item[source] as number
    return (
        <DelayedTextField numbersOnly type='number' size='small' delay={250} fullWidth onChange={(v: number) => onChange(v)} value={_value} disabled={disabled} />
    )
}

type EditFieldProps<E> = {
    title?: string;
    item?: E;
    readonly?: boolean;
    onChange?: (v: any) => void;
} & ({
    value: (string | null | number);
    source?: never;
} | {
    value?: never;
    source: keyof E;
})

export function TextFieldEdit<E>(props: EditFieldProps<E>): EditComponentChild {
    const { item, value, source, readonly, onChange } = props
    let disabled = readonly
    if (typeof readonly === 'undefined') {
        disabled = false
    }
    if (value || value === '') {
        return <DelayedTextField size='small' fullWidth value={value} disabled={true} />
    }
    const _value = (item && source) ? (item[source] || '') : ''
    return (
        <DelayedTextField disabled={disabled} size='small' delay={250} fullWidth onChange={(v: any) => onChange && onChange(v)} value={_value} />
    )
}


export function TextAreaEdit(props: any): EditComponentChild {
    const { item, source, readonly, onChange } = props
    let disabled = readonly
    if (typeof readonly === 'undefined') {
        disabled = false
    }
    const value = item[source] || ""
    return (
        <DelayedTextField delay={250} multiline fullWidth onChange={(v: any) => onChange(v)} value={value} disabled={disabled} />
    )
}


export function DateTimeFieldEdit<E>
    (props: { title: string, source?: keyof E, readonly?: boolean, item?: any, value?: (number | null), unit: 'ms' | 's', onChange?: (ms: number) => void })
    : EditComponentChild {
    const { readonly, item, value, source, unit, onChange } = props

    let _value: (number | null) = null
    if (item && source) {
        const tmp = parseInt(item[source])
        _value = isNaN(tmp) ? null : tmp
    } else if (typeof value !== 'undefined' && value !== null) {
        _value = value
        if (isNaN(_value)) {
            _value = null
        }
    }

    if (_value !== null && unit === 's') {
        _value = _value * 1000
    }
    const dt = _value !== null ? DateTime.fromMillis(_value) : null

    if (readonly) {
        const format: Intl.DateTimeFormatOptions = {
            ...DateTime.DATETIME_SHORT_WITH_SECONDS,
            timeZoneName: "short",
            hour: '2-digit'
        }
        return (
            <TextField fullWidth size='small' disabled value={dt ? dt.setLocale('da').toLocaleString(format) : ''} />
        )
    }
    return (
        <LocalizationProvider adapterLocale='da' dateAdapter={AdapterLuxon}>
            <DateTimePicker
                views={['day', 'hours', 'minutes', 'seconds']}
                format="yyyy/MM/dd HH:mm:ss"
                value={dt}
                onChange={(v: DateTime | null) => {
                    console.log({ v, _value })
                    if (v !== null && onChange) { onChange(v.toMillis()) }
                }}
            />
        </LocalizationProvider>
    )
}

export function TimeStampEditField<E>
    (props: { title: string, source?: keyof E, readonly?: boolean, item?: any, value?: (number | null), unit: 'ms' | 's', onChange?: (ms: number) => void }): EditComponentChild {
    const { onChange, item, value, source, unit, readonly } = props
    let _value: (number | null) = null
    if (item && source) {
        const tmp = parseInt(item[source])
        _value = isNaN(tmp) ? null : tmp
    } else if (typeof value !== 'undefined' && value !== null) {
        _value = value
        if (isNaN(_value)) {
            _value = null
        }
    }

    if (_value !== null && unit === 's') {
        _value = _value * 1000
    }

    const dt = _value !== null ? DateTime.fromMillis(_value).toUTC() : null

    if (readonly) {
        const format: Intl.DateTimeFormatOptions = {
            ...DateTime.DATETIME_SHORT_WITH_SECONDS,
            timeZoneName: "short",
            hour: '2-digit'
        }
        return (
            <TextField fullWidth size='small' disabled value={dt ? dt.setLocale('da').toLocaleString(format) : ''} />
        )
    }

    const year = dt ? dt.year : 1970
    const month = dt ? dt.month : 1
    const day = dt ? dt.day : 1
    const hour = dt ? dt.hour : 0
    const minute = dt ? dt.minute : 0
    const second = dt ? dt.second : 0

    console.log({ year, month, day, hour, minute, second })

    const dateToMillis = (year: number, month: number, day: number, hour: number, minute: number, second: number) => {
        return DateTime.utc(year, month, day, hour, minute, second).toMillis()
    }

    return (
        <>
            <TextField label='Year' size='small' type='number' sx={{ width: 96 }} value={year}             
                onChange={(evt : any) => {
                    const v = parseInt(`${evt.target.value}`)
                    if (isNaN(v)) {
                        return
                    }
                    // Create date time object from current values
                    const date = dateToMillis(v, month, day, hour, minute, second)
                    if (onChange) {
                        if (isNaN(date)) {
                            const ms = dt?.toMillis() || 0
                            onChange(unit === 'ms' ? ms : ~~(ms / 1000))
                            return
                        } 
                        onChange(unit === 'ms' ? date : ~~(date / 1000))
                    }
                }}
            />
            <TextField label='Month' size='small' type='number' sx={{ width: 64 }} value={month}             
                onChange={(evt : any)=> {
                    const v = parseInt(`${evt.target.value}`)
                    if (isNaN(v)) {
                        return
                    }
                    // Create date time object from current values
                    const date = dateToMillis(year, v, day, hour, minute, second)   
                    if (onChange) {
                        if (isNaN(date)) {
                            const ms = dt?.toMillis() || 0
                            onChange(unit === 'ms' ? ms : ~~(ms / 1000))
                            return
                        } 
                        onChange(unit === 'ms' ? date : ~~(date / 1000))
                    }
                }} />
            <TextField label='Day' size='small' type='number' sx={{ width: 64 }} value={day}             
                onChange={(evt : any) => {
                    const value = evt.target.value
                    const v = parseInt(`${value}`)
                    if (isNaN(v)) {
                        return
                    }
                    // Create date time object from current values
                    const date = dateToMillis(year, month, v, hour, minute, second)
                    if (onChange) {
                        if (isNaN(date)) {
                            const ms = dt?.toMillis() || 0
                            console.log('hello', {dt, ms})                            
                            onChange(unit === 'ms' ? ms : ~~(ms / 1000))
                            return
                        } 
                        onChange(unit === 'ms' ? date : ~~(date / 1000))
                    }
                }} />
            <div style={{ display: 'inline-block', width: 8, minWidth: 8 }}></div>
            <TextField label='Hour' size='small' type='number' sx={{ width: 64 }} value={hour}             
                onChange={(evt: any) => {
                    const v = parseInt(`${evt.target.value}`)
                    if (isNaN(v)) {
                        return
                    }
                    // Create date time object from current values
                    const date = dateToMillis(year, month, day, v, minute, second)
                    if (onChange) {
                        if (isNaN(date)) {
                            const ms = dt?.toMillis() || 0
                            onChange(unit === 'ms' ? ms : ~~(ms / 1000))
                            return
                        } 
                        onChange(unit === 'ms' ? date : ~~(date / 1000))
                    }
                }} />
            <TextField label='Minute' size='small' type='number' sx={{ width: 64 }} value={minute}
                onChange={(evt : any) => {
                    const v = parseInt(`${evt.target.value}`)
                    if (isNaN(v)) {
                        return
                    }
                    // Create date time object from current values
                    const date = dateToMillis(year, month, day, hour, v, second)
                    if (onChange) {
                        if (isNaN(date)) {
                            const ms = dt?.toMillis() || 0
                            onChange(unit === 'ms' ? ms : ~~(ms / 1000))
                            return
                        } 
                        onChange(unit === 'ms' ? date : ~~(date / 1000))
                    }
                }} />
            <TextField label='Second' size='small' type='number' sx={{ width: 64 }} value={second}
                onChange={(evt : any) => {
                    const v = parseInt(`${evt.target.value}`)
                    if (isNaN(v)) {
                        return
                    }
                    // Create date time object from current values
                    const date = dateToMillis(year, month, day, hour, minute, v)
                    if (onChange) {
                        if (isNaN(date)) {
                            const ms = dt?.toMillis() || 0
                            onChange(unit === 'ms' ? ms : ~~(ms / 1000))
                            return
                        } 
                        onChange(unit === 'ms' ? date : ~~(date / 1000))
                    }
                }} />
        </>
    )
}



export function ElapsedTimeFieldStaticEdit<E>
    (props: { title: string, readonly?: boolean, item?: E, value: (number | null | undefined), source?: keyof E, unit: 'ms' | 's' })
    : EditComponentChild {
    const { item, value, source, unit } = props
    let totalseconds = (item && source) ? item[source] : value
    if (typeof totalseconds !== 'number' || totalseconds === null) {
        return (<TextField size='small' fullWidth inputProps={{ min: 0, style: { textAlign: 'right' } }} disabled value='' />)
    }
    if (unit === 'ms') {
        totalseconds = Math.floor(totalseconds / 1000)
    }
    let hours = Math.floor(totalseconds / 3600)
    let minutes = Math.floor(totalseconds / 60) % 60
    let seconds = totalseconds % 60
    if (hours < 10) {
        // @ts-ignore
        hours = '0' + hours
    }
    if (minutes < 10) {
        // @ts-ignore
        minutes = '0' + minutes
    }
    if (seconds < 10) {
        // @ts-ignore
        seconds = '0' + seconds
    }
    return (<TextField size='small' fullWidth inputProps={{ min: 0, style: { textAlign: 'right' } }} disabled value={`${hours}:${minutes}:${seconds}`} />)
}

function EditComponent<E>(props: EditComponentProps<E>) {
    const { title, disabled, item, onChange, save, cancel, dirty, onSave, onCancel, elevation } = props
    const _children = Array.isArray(props.children) ? props.children : [props.children]
    if (!item) {
        return null;
    }
    return (
        <Paper elevation={elevation} sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
            {title && <Typography variant="h6" sx={{ padding: 1 }}>{title}</Typography>}
            <Grid container spacing={2}>
                {_children.map((c, idx: number) => (
                    <React.Fragment key={idx}>
                        <Grid sx={{ marginTop: 1 }} item xs={4}>
                            <span>{c.props.title}: </span>
                        </Grid>
                        <Grid item xs={8}>
                            {React.createElement(
                                c.type,
                                {
                                    ...c.props,
                                    readonly: disabled || c.props['readonly'],
                                    item: item,
                                    onChange: ((value: any) => {
                                        console.log({ value }); onChange(c.props.source, value)
                                    })
                                }
                            )}
                        </Grid>
                    </React.Fragment>
                ))}
                {save &&
                    <Grid item xs={cancel ? 6 : 12}>
                        <Button fullWidth variant='contained' color='primary' onClick={() => onSave()} disabled={disabled || !dirty}>Save</Button>
                    </Grid>
                }
                {cancel &&
                    <Grid item xs={save ? 6 : 12}>
                        <Button fullWidth variant='contained' color='inherit' onClick={() => onCancel()} disabled={!dirty}>Cancel</Button>
                    </Grid>
                }

            </Grid>
        </Paper>
    )
}

export default EditComponent
