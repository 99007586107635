import { Box } from "@mui/system"
import { AudioFileMetadata, Observation } from "../../redux/missionsModel"
import AddObservationForm from "./AddObservationForm"
import ListObservations from "./ListObservations"

export interface AudioFileEditObservationsProps {
    observations: Observation[]
    missionId: string
    audioFileId: string
    metadata: AudioFileMetadata
    audioTimeProvider: () => number | undefined
    onObservationAdded: (observation: Observation) => void
}


const AudioFileEditObservations = ({ missionId, audioFileId, metadata, observations, onObservationAdded, audioTimeProvider } : AudioFileEditObservationsProps) => {
    console.log({observations})
    if (!observations || !metadata) {
        return null;
    }
    return <Box>
        <AddObservationForm 
            onObservationAdded={(observation) => onObservationAdded(observation)}
            metadata={metadata} 
            audioTimeProvider={audioTimeProvider} />
        <ListObservations missionId={missionId} audioFileId={audioFileId} metadata={metadata} observations={observations} />
    </Box>
}

export default AudioFileEditObservations