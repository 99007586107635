import { CognitoUser } from 'amazon-cognito-identity-js';
import { ReduxAction } from '../actionModel';
import { SideBarMenuItem, User } from "./appReducer"

export const APP_INIT = "[app] init"
export const APP_INIT_AUTH = "[app] init auth"
export const APP_REQUEST_LOGIN = "[app] request login"
export const APP_LOGIN_COMPLETE = "[app] login complete"
export const APP_REQUEST_NEW_PASSWORD = "[app] request new password"
export const APP_REQUEST_LOGOUT = "[app] request logout"
export const APP_SET_USER = "[app] set user"
export const APP_SET_LOGGED_OUT = "[app] set logged out"
export const APP_SET_CHALLENGE = "[app] set challenge"
export const APP_RESET_CHALLENGE = "[app] reset challenge"
export const APP_FORGOT_PASSWORD = "[app] forgot password"
export const APP_FORGOT_PASSWORD_SUBMIT = "[app] forgot password submit"


export const APP_SET_API_VERSION = "[app] set api version"
export const APP_SET_SEASONS = "[app] set seasons"

export const APP_SET_SECONDARY_MENU = "[app] set secondary menu"


export const init = () : ReduxAction => ({
    type: APP_INIT
})


export interface SetUserAction {
    type: string;
    payload: User;
}

export const setUser = (user: User) : SetUserAction => ({
    type: APP_SET_USER,
    payload: user
})

export const setLoggedOut = () => ({
    type: APP_SET_LOGGED_OUT
})

export interface RequestLoginAction extends ReduxAction { 
    payload: {
        username: string;
        password: string;
    }
}

export interface RequestNewPasswordAction extends ReduxAction {
    payload: {
        username: string;
        name: string;
        password: string;
    }
}

export interface ForgotPasswordSubmitAction extends ReduxAction {
    payload: {
        username: string;
        code: string;
        password: string;
    }
}
export const forgotPassword = (username: string) => ({
    type: APP_FORGOT_PASSWORD,
    payload: username
})

export const forgotPasswordSubmit = (username: string, code: string, password: string) : ForgotPasswordSubmitAction => ({
    type: APP_FORGOT_PASSWORD_SUBMIT,
    payload: { username, code, password }
})

export const requestLogin = (username: string, password: string) : RequestLoginAction => ({
    type: APP_REQUEST_LOGIN,
    payload: { username, password}
})

export const loginComplete = () : ReduxAction => ({
    type: APP_LOGIN_COMPLETE
})

export const requestLogout = () => ({
    type: APP_REQUEST_LOGOUT
})

export const requestNewPassword = (username: string, name: string, password: string) : RequestNewPasswordAction => ({
    type: APP_REQUEST_NEW_PASSWORD,
    payload: { username, name: name, password }
})

export const initAuth = () => ({
    type: APP_INIT_AUTH
})

export interface CognitoChallengeParam { 
    requiredAttributes: any[];
}      

export interface SignInCognitoResponse extends CognitoUser {
    username: string,
    challengeParam: CognitoChallengeParam
}

export interface SimpleUser { 
    username: string;
}

export interface ResetChallengeAction extends ReduxAction {}

export interface SetChallengeAction {
    type : string;
    payload: {
        user: SignInCognitoResponse | null | SimpleUser;
        challengeName: string | null;
        challengeParam: CognitoChallengeParam | null;
    }
}

export const setChallenge = (user: SignInCognitoResponse | SimpleUser | null, challengeName: string, challengeParam: CognitoChallengeParam | null) : SetChallengeAction => ({
    type: APP_SET_CHALLENGE,
    payload: { user, challengeName, challengeParam }
})



export const resetChallenge = () : ResetChallengeAction => ({
    type: APP_RESET_CHALLENGE
})

export interface SetApiVersionAction extends ReduxAction {
    payload: { version: string }
}

export const setApiVersion = (version: string) : SetApiVersionAction => ({
    type: APP_SET_API_VERSION,
    payload: { version }
})

export interface SetSecondaryMenuAction extends ReduxAction {
    payload: { items: SideBarMenuItem[]}
}

export const setSecondaryMenu = (items: SideBarMenuItem[]) : SetSecondaryMenuAction => ({
    type: APP_SET_SECONDARY_MENU,
    payload: { items }
})