import React from 'react'
import Canvas from './Canvas'

class Animation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { angle: 0 };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    console.log('animation mounted')
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  updateAnimationState(timestamp) {
    const prevTimestamp = this.state.timestamp ? this.state.timestamp : timestamp
    const delta = timestamp - prevTimestamp
    const data = this.props.dataProvider(delta)
    const fps = Math.floor(1000 / delta)
    if (delta && delta < 15) {
      this.tO = setTimeout(this.updateAnimationState, 10)
      return 
    }    
    this.setState(() => ({ timestamp, data: data, fps }));
    this.rAF = requestAnimationFrame(this.updateAnimationState)
  }

  componentWillUnmount() {
    if (this.tO) clearTimeout(this.tO)
    cancelAnimationFrame(this.rAF);
  }
  
  render() {
    return <Canvas className={this.props.className} width={this.props.width} height={this.props.height} data={this.state.data} fps={this.state.fps} draw={this.props.draw} onClick={this.props.onClick}/>;
  }
}

export default Animation
