import React, { useEffect, useRef, useState } from 'react'

import {
  Box,
  Link,
  Paper,
  TextField,
  Button,
  Grid,
  CssBaseline,
  Avatar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { requestLogin, initAuth, forgotPassword, requestLogout } from '../../redux/app/appActions'
import { AppState } from '../../redux/reducers'
import ForgotPasswordComponent from './ForgotPasswordComponent'
import { AuthChallenge } from '../../redux/app/appReducer'
import { hideMessage } from '../../redux/ui/uiActions'
import NewPasswordRequiredComponent from './NewPasswordRequiredComponent'
import { useTheme } from '@mui/material/styles'
/*
const useStyles = (theme: Theme) => makeStyles(() => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
      "url(https://source.unsplash.com/1600x900/?nature,water)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],        
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))()
*/

const NotPermitted = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  return (
  <Grid sx={{ height: '100vh' }} container component="main">      
      <Grid
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/1600x900/?nature,water)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: theme.palette.grey[50],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        item
        xs={false}
        sm={4}
        md={8}
      />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div
          style={{
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Typography variant="h5">
              The logged in user is not permitted to access this application
            </Typography>
            <Button variant="contained" color="primary" onClick={() => dispatch(requestLogout())}>Logout</Button>
          <Avatar sx={{ margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main }}>
            <LockOutlinedIcon />
          </Avatar>
        </div>
        <Box mt={5}>
          <Copyright version={'version'} />
        </Box>
      </Grid>
    </Grid>)
}

const Copyright = ({ version }: { version: string }) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Flydata'}
      <Link color="inherit" href="https://material-ui.com/"></Link> {`v${version}`}
    </Typography>
  )
}

const SignInComponent = function () {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  //const classes = useStyles(theme);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <div
        style={{
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(evt) => setUsername(evt.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
          autoComplete="current-password"
        />
        <Button
          onClick={() => {
            dispatch(requestLogin(username, password))
          }}
          fullWidth
          variant="contained"
          color="primary"
          sx={{ margin: theme.spacing(3, 0, 2) }}
        >
          Sign In
        </Button>
        <Button
          sx={{ marginTop: 1 }}
          fullWidth
          size="small"
          color="inherit"
          variant="text"
          onClick={() => dispatch(forgotPassword(username))}
        >
          Forgot password
        </Button>
      </div>
    </React.Fragment>
  )
}

const Challenge = function ({ challenge }: { challenge: AuthChallenge }) {
  if (challenge.name === 'FORGOT_PASSWORD') {
    return <ForgotPasswordComponent username={challenge.user?.username} />
  } else if (challenge.name === 'NEW_PASSWORD_REQUIRED') {
    return <NewPasswordRequiredComponent username={challenge.user?.username} />
  } else {
    return null
  }
}

export const AuthComponent = function (props: any) {
  const ref = useRef(false)
  const theme = useTheme()
  const dispatch = useDispatch()
  const { uiVersion, challenge, loggedIn, authReady, user } = useSelector((state: AppState) => state.app)
  const { message } = useSelector((state: AppState) => state.ui)

  useEffect(() => {
    if (ref.current) {
      return
    }
    ref.current = true
    dispatch(initAuth())
  }, [dispatch, ref])

  if (!authReady ) {
    return <div></div>
  }

  const permitted = user && Boolean(user.groups?.find(g => g.startsWith('Flydata')))
  if (loggedIn && permitted) {
    console.log('logged in')
    return props.children
  }
  if (loggedIn && !permitted) {
    return <NotPermitted />
  }
  return (
    <Grid sx={{ height: '100vh' }} container component="main">
      <Dialog
        open={Boolean(message)}
        onClose={() => dispatch(hideMessage())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message?.title}</DialogTitle>
        <DialogContent>
          <Typography>{message?.text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(hideMessage())} variant="outlined" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <CssBaseline />
      <Grid
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/1600x900/?nature,water)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: theme.palette.grey[50],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        item
        xs={false}
        sm={4}
        md={8}
      />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <div
          style={{
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ margin: theme.spacing(1), backgroundColor: theme.palette.secondary.main }}>
            <LockOutlinedIcon />
          </Avatar>
          {challenge === null ? <SignInComponent /> : <Challenge challenge={challenge} />}
        </div>
        <Box mt={5}>
          <Copyright version={uiVersion} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default SignInComponent
