import { combineReducers } from 'redux'
import app, { AppStore } from '../app/appReducer'
import ui, { UiStore } from '../ui/uiReducer'
import missions, { MissionsStore } from '../missionList/missionsReducer';
import missionEdit, { MissionEditStore } from '../missionEdit/missionEditReducer';
import audioFileEdit, { AudioFileEditStore} from '../audioFileEdit/audioFileEditReducer';
export interface AppState {
  app: AppStore;
  ui: UiStore;
  missions: MissionsStore;
  missionEdit: MissionEditStore;
  audioFileEdit: AudioFileEditStore;
}

const appReducer = combineReducers({
  app, ui, missions, missionEdit, audioFileEdit
});

const rootReducer = (state: any, action: any) => {
  // TODO - logout logic
  return appReducer(state, action)
}

export default rootReducer