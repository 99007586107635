import { takeEvery, takeLeading, put, apply } from '@redux-saga/core/effects'

import { showMessage } from '../ui/uiActions'
import flydataAudioApi from '../../services/flydataAudioApi'
import { CreateMissionAction, MISSIONS_CREATE_MISSION, MISSIONS_FETCH, MISSIONS_INIT, MissionsInitAction, fetchMissions, setMissions } from './missionsActions'
import { Mission } from '../missionsModel'
import { ReduxAction } from '../actionModel'
import { setSecondaryMenu } from '../app/appActions'


function* processInit(action: MissionsInitAction) {
  try {
    yield put(fetchMissions())
    yield put(setSecondaryMenu([]))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error fetching missions', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processFetch(action: ReduxAction) {
  try {
    const missions: Mission[] = yield apply(flydataAudioApi, flydataAudioApi.fetchMissions, [2])
    yield put(setMissions(missions))
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error fetching missions', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }
}

function* processCreateMission(action: CreateMissionAction) {
  try {
    yield apply(flydataAudioApi, flydataAudioApi.createMission, [action.payload.name])
    yield put(fetchMissions())
  } catch (error: any) {
    try {
      console.log(error)
      yield put(showMessage({ title: 'Error creating mission', text: error?.message }))
    } catch (e2) {
      console.log(e2)
    }
  }

}

export default function* watcher() {
  yield takeLeading(MISSIONS_INIT, processInit)
  yield takeEvery(MISSIONS_FETCH, processFetch)
  yield takeEvery(MISSIONS_CREATE_MISSION, processCreateMission)
}
