import { useDispatch, useSelector } from "react-redux";
import { requestLogout } from "./redux/app/appActions";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from "@mui/material";
import MissionListPage from "./pages/MissionListPage";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MissionEditPage from "./pages/MissionEditPage";
import { AppState } from "./redux/reducers";
import AudioFileEditPage from "./pages/AudioFileEditPage";
import Dashboard from "./components/sidebar/Dashboard";
import { UiMessage } from "./redux/ui/uiReducer";
import { hideMessage } from "./redux/ui/uiActions";
import React from "react";

const ConditionalLoadingBar = ({ loading }: { loading: boolean }) => {
  return loading ? <LinearProgress /> : <div style={{ height: '4px' }}></div>
}

function MessageBox(props : { show: boolean, message: UiMessage | null, onClose: () => void}) : React.ReactElement {
  const { show, message, onClose } = props
  return (<Dialog
    open={show}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{message?.title}</DialogTitle>
    <DialogContent>
      <Typography>{message?.text}</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => onClose()}
        variant="outlined"
        color="primary"
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>)
}

function UiInfo() {
  const { message, loading } = useSelector((state: AppState) => state.ui)
  const dispatch = useDispatch()

  return (<>
    <ConditionalLoadingBar loading={loading} />
    <MessageBox show={Boolean(message)} message={message} onClose={() => dispatch(hideMessage())} />
  </>)
}

function App() {
  console.log('render app')
  const dispatch = useDispatch()
  return (
    <>
      <BrowserRouter>
        <Dashboard version={'v0.1'} title="Flydata Audio" onSignOut={() => dispatch(requestLogout())}>
          <UiInfo />
          <Routes>
            <Route path='/' element={<MissionListPage />} />
            <Route path='/missions' element={<MissionListPage />} />
            <Route path='/missions/:id' element={<MissionEditPage />} />
            <Route path='/missions/:missionId/audiofile/:audiofileId' element={<AudioFileEditPage />} />
          </Routes>
        </Dashboard>
      </BrowserRouter>
    </>
  )
}

export default App
