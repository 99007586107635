import { Drawer, Grid } from '@mui/material'
import EditComponent, { NumberFieldEdit, TextFieldEdit } from '../edit/EditComponent'
import { produce } from 'immer'
import deepEqual from 'deep-equal'
import { Observation } from '../../redux/missionsModel'
import { useEffect, useState } from 'react'
import { StaticDropdownEdit } from '../edit/DropdownField'

export interface EditObservationDialogProps {

    selectedObservation?: Observation
    onClose: () => void
    onSave: (observation: Observation) => void
}

export interface EditTransectDialogProps {
    dirty: boolean
    observation: Observation
    onChange: (item: Observation) => void
    onSave: () => void
    onClose: () => void
}

const transectOptions = [{ label: 'Transect', value: 'transect' }, { label: 'Total', value: 'total' }]

const valueToOption = (value: string) => {
    return transectOptions.find((e) => e.value === value)
}

const startEndOptions = [{ label: 'Transect Start', value: 'TRANSECT_START' }, { label: 'Transect End', value: 'TRANSECT_END' }]


const startEndValueToOption = (value: string) => {
    const res = startEndOptions.find((e) => e.value === value)
    return res
}

const EditTransectDialog = ({ dirty, onChange, observation, onClose, onSave }: EditTransectDialogProps) => {
    return <EditComponent
        dirty={dirty}
        save
        cancel
        title="Edit observation"
        onChange={(prop: keyof Observation, item: any) => {
            const newShadow = produce(observation, (draft) => {
                if (draft) {
                    draft[prop] = item
                }
            })
            onChange(newShadow)
        }}
        item={observation}
        onCancel={() => onClose()}
        onSave={() => onSave()}
    >
        <TextFieldEdit source="species" title="Species" />
        <NumberFieldEdit source="count" title="Count" />
        <TextFieldEdit source="distanceBand" title="Distance band" />
        <TextFieldEdit source="age" title="Age" />
        <TextFieldEdit source="behaviour" title="Behaviour" />
        <TextFieldEdit source="sex" title="Sex" />
        <TextFieldEdit source="comment" title="Comment" />
        <NumberFieldEdit source="audioTime" title="Audio time" />
        <StaticDropdownEdit
            source="observationType"
            title="Type"
            options={transectOptions}
            mapFn={(e: any) => e}
            itemMapFn={(e: any) => valueToOption(e)}
            optionToValue={(e: any) => e.value}
        />
    </EditComponent>
}


const EditTransectStartEndDialog = ({ dirty, onChange, observation, onClose, onSave }: EditTransectDialogProps) => {
    return <EditComponent
        dirty={dirty}
        save
        cancel
        title="Edit observation"
        onChange={(prop: keyof Observation, item: any) => {
            const newShadow = produce(observation, (draft) => {
                if (draft) {
                    draft[prop] = item
                }
            })
            onChange(newShadow)
        }}
        item={observation}
        onCancel={() => onClose()}
        onSave={() => onSave()}
    >
        <NumberFieldEdit source="audioTime" title="Audio time" />
        <StaticDropdownEdit
            source="observationType"
            title="Type"
            options={startEndOptions}
            mapFn={(e: any) => e}
            itemMapFn={(e: any) => startEndValueToOption(e) }
            optionToValue={(e: any) => e.value}
        />
    </EditComponent>
}

const EditIntensityDialog = ({ dirty, onChange, observation, onClose, onSave }: EditTransectDialogProps) => {
    return <EditComponent
        dirty={dirty}
        save
        cancel
        title="Edit observation"
        onChange={(prop: keyof Observation, item: any) => {
            const newShadow = produce(observation, (draft) => {
                if (draft) {
                    draft[prop] = item
                }
            })
            onChange(newShadow)
        }}
        item={observation}
        onCancel={() => onClose()}
        onSave={() => onSave()}
    >
        <NumberFieldEdit source="intensity" title="Intensity" />
        <NumberFieldEdit source="audioTime" title="Audio time" />
        <TextFieldEdit readonly source="observationType" title="Type" />
    </EditComponent>
}

const EditObservationDialog = ({ selectedObservation, onClose, onSave }: EditObservationDialogProps) => {
    const [shadow, setShadow] = useState<Observation | undefined>(undefined)
    const [dialogType, setDialogType] = useState<string>('SPECIES')

    useEffect(() => {
        setShadow(selectedObservation ? { ...selectedObservation } : undefined)
        if (selectedObservation) {
            switch (selectedObservation.observationType) {
                case 'SEA_STATE_CHANGE':
                    setDialogType('INTENSITY')
                    break;
                case 'SUN_INTENSITY_CHANGE':
                    setDialogType('INTENSITY')
                    break;
                case 'TRANSECT_START':
                    setDialogType('START_END')
                    break;
                case 'TRANSECT_END':
                    setDialogType('START_END')
                    break;
                default:
                    setDialogType('SPECIES')
            }

        }
    }, [selectedObservation, setShadow, setDialogType])

    const dirty = Boolean(selectedObservation && !deepEqual(selectedObservation, shadow))

    return (<Drawer anchor="right" open={Boolean(selectedObservation)} onClose={() => onClose()}>
        <Grid sx={{ maxWidth: 480, marginLeft: 2, marginTop: 8, paddingRight: 6 }} container spacing={1}>
            {selectedObservation && shadow && (
                <Grid item xs={12}>
                    {dialogType === 'SPECIES' ? (
                        <EditTransectDialog
                            dirty={dirty}
                            onChange={item => setShadow(item)}
                            observation={shadow}
                            onClose={() => onClose()}
                            onSave={() => {
                                onSave(shadow!)
                                onClose()
                            }}
                        />
                    ) :
                        dialogType === 'INTENSITY' ? (
                            <EditIntensityDialog
                                dirty={dirty}
                                onChange={item => setShadow(item)}
                                observation={shadow}
                                onClose={() => onClose()}
                                onSave={() => {
                                    onSave(shadow!)
                                    onClose()
                                }} />
                        ) : <EditTransectStartEndDialog
                            dirty={dirty}
                            onChange={item => setShadow(item)}
                            observation={shadow}
                            onClose={() => onClose()}
                            onSave={() => {
                                onSave(shadow!)
                                onClose()
                            }} />
                    }
                </Grid>
            )}
        </Grid>
    </Drawer>)
}

export default EditObservationDialog;