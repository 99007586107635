
import { MISSIONS_SET, SetMissionsAction } from './missionsActions';
import { Mission } from '../missionsModel';


export interface MissionsStore {
    missions:  Mission[]
}

const initialState: MissionsStore = {
  missions: []  
}

const missions = (state = initialState, action: any) : MissionsStore => {
  switch (action.type) {
    case MISSIONS_SET:
      const setMissionsAction : SetMissionsAction  = action
      return { ...state, missions: setMissionsAction.payload.missions }    
    default:
      return state
  }
}

export default missions
